import {
  AddressValue,
  Address,
  BigUIntValue,
  TokenIdentifierValue
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import {
  getTokenMetadata,
  performTransaction,
  TransactionResponse
} from './generalRequests';

export const setSourceContractAddress = async (
  contractAddress: string,
  sourceAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setSourceContractAddress',
    contractAddress,
    [new AddressValue(new Address(sourceAddress))],
    20000000
  );
};

export const setNFTSourceContractAddress = async (
  contractAddress: string,
  sourceAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setNftFarmSourceContractAddress',
    contractAddress,
    [new AddressValue(new Address(sourceAddress))],
    20000000
  );
};

export const setVestingScAddress = async (
  contractAddress: string,
  value: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setVestingSourceContractAddress',
    contractAddress,
    [new AddressValue(new Address(value.trim()))]
  );
};

export const setAggregatorContractAddress = async (
  contractAddress: string,
  aggregatorAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setAggregatorScAddress',
    contractAddress,
    [new AddressValue(new Address(aggregatorAddress))],
    20000000
  );
};

export const setAggregatorContractAddressPairRouter = async (
  contractAddress: string,
  aggregatorAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setAggregatorAddress',
    contractAddress,
    [new AddressValue(new Address(aggregatorAddress))],
    20000000
  );
};

export const setSpecialAccessAddress = async (
  contractAddress: string,
  address: string
): Promise<TransactionResponse> => {
  return await performTransaction('setSpecialAccessAddress', contractAddress, [
    new AddressValue(new Address(address.trim()))
  ]);
};

export const setDivisionSafetyConstant = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction(
    'setDivisionSafetyConstant',
    contractAddress,
    [new BigUIntValue(new BigNumber('1000000000000'))]
  );
};

export const setFeeGroupRegistration = async (
  contractAddress: string,
  token: string,
  amount: string
): Promise<TransactionResponse> => {
  const tokenDecimals = (await getTokenMetadata(token))?.decimals ?? 0;

  return await performTransaction('setFeeGroupRegistration', contractAddress, [
    new TokenIdentifierValue(token),
    new BigUIntValue(new BigNumber(amount).shiftedBy(tokenDecimals))
  ]);
};

export const setFeeContractDeployment = async (
  contractAddress: string,
  token: string,
  amount: string
): Promise<TransactionResponse> => {
  const tokenDecimals = (await getTokenMetadata(token))?.decimals ?? 0;

  return await performTransaction('setFeeContractDeployment', contractAddress, [
    new TokenIdentifierValue(token),
    new BigUIntValue(new BigNumber(amount).shiftedBy(tokenDecimals))
  ]);
};

export const setTaxPerAction = async (
  contractAddress: string,
  token: string,
  amount: string
): Promise<TransactionResponse> => {
  const tokenDecimals = (await getTokenMetadata(token))?.decimals ?? 0;

  return await performTransaction('setTaxPerAction', contractAddress, [
    new TokenIdentifierValue(token),
    new BigUIntValue(new BigNumber(amount).shiftedBy(tokenDecimals))
  ]);
};

export const collectAccumulatedTax = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('collectAccumulatedTax', contractAddress, []);
};
