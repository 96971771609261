import React, { useState } from 'react';
import { whitelistOrRemoveWhitelistForAddress } from 'apiRequests/pairTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

const PairWhitelist = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [address, setAddress] = useState<string>();

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Whitelist address</b>
        </p>

        <TextField
          type='text'
          label='Address'
          placeholder='Address'
          callback={(newValue) => setAddress(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(whitelistOrRemoveWhitelistForAddress, [
                contractAddress,
                address,
                'whitelist'
              ])
            }
          >
            Whitelist
          </button>
          <button
            className='btn btn-danger mr-4'
            onClick={() =>
              handleContractCall(whitelistOrRemoveWhitelistForAddress, [
                contractAddress,
                address,
                'removeWhitelist'
              ])
            }
          >
            Remove whitelist
          </button>
        </div>
      </div>
    </div>
  );
};

export default PairWhitelist;
