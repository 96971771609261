import React from 'react';

export interface PairInfoProps {
  title: string;
  description: string;
}

const PairInfo = ({ title, description }: PairInfoProps) => {
  return (
    <div className='d-flex flex-row mt-2'>
      <span>
        <b>{title}</b>
      </span>
      <span className='ml-2'>{description}</span>
    </div>
  );
};

export default PairInfo;
