import React, { useState } from 'react';
import { removePair } from 'apiRequests/aggregatorTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';
export const RemovePair = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [pairAddress, setPairAddress] = useState('');
  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Remove Pair</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='Pair Address'
              placeholder='Pair Address'
              callback={(newValue) => setPairAddress(newValue)}
            />

            <div className='mt-1'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(removePair, [contractAddress, pairAddress])
                }
              >
                Remove Pair
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
