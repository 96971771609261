import { gql } from '@apollo/client';

export const UPGRADE_ALL_FARMS_V1 = gql`
  mutation upgradeAllFarmsV1($sender: String!) {
    transactions: upgradeAllFarmsV1(sender: $sender) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;

export const UPGRADE_ALL_FARMS_V2 = gql`
  mutation upgradeAllFarmsV2($sender: String!) {
    transactions: upgradeAllFarmsV2(sender: $sender) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
