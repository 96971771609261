import React from 'react';
import { Code } from '@multiversx/sdk-core/out/smartcontracts';
import { deployOrUpgrade } from 'apiRequests';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { FarmStakingVersion } from 'types/FarmVersion';
import { DeployAggregatorContract } from './DeployAggregatorContract';
import DeployDummyPairContract from './DeployDummyPairContract/DeployDummyPairContract';
import DeployFarmStakingContract from './DeployFarmStakingContract/DeployFarmStakingContract';
import DeployFaucetContract from './DeployFaucetContract/DeployFaucetContract';
import DeployNftFarmStakingContract from './DeployNftFarmStakingContract/DeployNftFarmStakingContract';
import DeployRouterContract from './DeployRouterContract/DeployRouterContract';
import DeployStakingRouterContract from './DeployStakingRouterContract/DeployStakingRouterContract';
import DeployVampiresContract from './DeployVampiresContract/DeployVampiresContract';
import DeployVestingContract from './DeployVestingContract/DeployVestingContract';
import LastDeployedContract from './LastDeployedContract/LastDeployedContract';

const DeployPage = () => {
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );

  const handleDeployOrUpgradeTransaction = async (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit = 25000000
  ) => {
    const response = await deployOrUpgrade(
      operation,
      address === '' ? undefined : address,
      code,
      args,
      gasLimit
    );
    setSessionId(response.sessionId ?? '');
  };

  const handleDeployOrUpgradeVampires = async (
    operation: 'deploy' | 'upgrade',
    address: string | undefined,
    code: Code,
    args: any[],
    gasLimit = 25000000
  ) => {
    const response = await deployOrUpgrade(
      operation,
      address === '' ? undefined : address,
      code,
      args,
      gasLimit,
      true,
      true,
      true,
      true
    );
    setSessionId(response.sessionId ?? '');
  };

  return (
    <div className='container mt-5'>
      <h2 className='mb-5'>Deploy contracts</h2>
      <LastDeployedContract sessionId={sessionId} />

      <DeployDummyPairContract
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployRouterContract
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployAggregatorContract
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployFarmStakingContract
        version={FarmStakingVersion.V2}
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployNftFarmStakingContract
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployStakingRouterContract
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployVestingContract
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployFaucetContract
        handleTransaction={handleDeployOrUpgradeTransaction}
      />
      <DeployVampiresContract
        handleTransaction={handleDeployOrUpgradeVampires}
      />
    </div>
  );
};
export default DeployPage;
