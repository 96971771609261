import React, { useEffect, useState } from 'react';

export const SearchBar = ({
  hint,
  onChange
}: {
  hint: string;
  onChange: (state: string) => void;
}) => {
  const [state, setState] = useState('');

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <>
      <input
        className='form-control'
        type='text'
        placeholder={hint}
        value={state}
        onChange={(event) => setState(event.target.value)}
      />
    </>
  );
};

export default SearchBar;
