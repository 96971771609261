import React, { useEffect } from 'react';
import ValueWithLabel from 'components/ValueWithLabel/ValueWithLabel';
import useGetPairSettings from 'hooks/useGetPairSettings';
import { SectionProps } from 'pages/PairRouter/SectionProps';
import { formatPercent } from 'utils/pairUtils';
import AmountWithLabel from './AmoutWithLabel';
import ManagedAddressesList from './ManagedAddressesView/ManagedAddressesList';
import TrustedSwapPairList from './TrustedSwapPairView/TrustedSwapPairList';

const PairViews = ({ contractAddress, refreshData }: SectionProps) => {
  const { isLoading, settings, init } = useGetPairSettings(contractAddress);

  useEffect(() => {
    if (!refreshData || isLoading) {
      return;
    }

    init();
  }, [refreshData]);

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Pair contract state</b>
        </p>

        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <ValueWithLabel
              label={'First Token id:'}
              value={settings?.firstTokenId}
            />
            <ValueWithLabel
              label={'Second token id:'}
              value={settings?.secondTokenId}
            />
            <AmountWithLabel
              label={'First token reserve:'}
              amount={settings?.firstTokenReserve?.toFixed()}
              decimals={settings?.firstTokenDecimals}
              tokenLabel={settings?.firstTokenId}
            />
            <AmountWithLabel
              label={'First token reserve:'}
              amount={settings?.secondTokenReserve?.toFixed()}
              decimals={settings?.secondTokenDecimals}
              tokenLabel={settings?.secondTokenId}
            />
            <ValueWithLabel
              label={'LP token id:'}
              value={settings?.lpTokenId}
            />
            <AmountWithLabel
              label={'First token reserve:'}
              amount={settings?.totalSupply?.toFixed()}
              decimals={settings?.lpTokenDecimals}
              tokenLabel={settings?.lpTokenId}
            />
            <ValueWithLabel label={'State:'} value={settings?.state} />
            <ValueWithLabel
              label={'Fee state:'}
              value={settings?.feeState ? 'enabled' : 'disabled'}
            />
            <ValueWithLabel
              label={'Total fee percent:'}
              value={formatPercent(settings?.totalFeePercent)}
            />
            <ValueWithLabel
              label={'Special fee percent:'}
              value={formatPercent(settings?.specialFeePercent)}
            />
            <ManagedAddressesList
              addresses={settings?.whitelistManagedAddresses}
            />
            <TrustedSwapPairList pairs={settings?.trustedSwapPairs} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PairViews;
