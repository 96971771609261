import { gql } from '@apollo/client';

export const UPGRADE_FARM_V1 = gql`
  mutation upgradeFarmV1(
    $sender: String!
    $farmAddress: String!
    $groupId: String!
  ) {
    transactions: upgradeFarmV1(
      sender: $sender
      farmAddress: $farmAddress
      groupId: $groupId
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;

export const UPGRADE_FARM_V2 = gql`
  mutation upgradeFarmV2(
    $sender: String!
    $farmAddress: String!
    $groupId: String!
  ) {
    transactions: upgradeFarmV2(
      sender: $sender
      farmAddress: $farmAddress
      groupId: $groupId
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
