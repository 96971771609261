import React, { useState } from 'react';
import { setFeeDetails } from 'apiRequests';
import TextField from 'components/TextField';
import { SectionProps } from '../SectionProps';

const SetFeeDetails = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [totalFeePercent, setTotalFeePercent] = useState('');
  const [specialFeePercent, setSpecialFeePercent] = useState('');
  const [feeAddress, setFeeAddress] = useState('');
  const [feeToken, setFeeToken] = useState('');

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set fee details</b>
        </p>

        <TextField
          type='text'
          label='Total fee percent'
          placeholder='Total fee percent'
          callback={(newValue) => setTotalFeePercent(newValue)}
          min={0}
          max={100}
        />

        <TextField
          type='text'
          label='Special fee percent'
          placeholder='Special fee percent'
          callback={(newValue) => setSpecialFeePercent(newValue)}
          min={0}
          max={100}
        />

        <TextField
          type='text'
          label='Fee address'
          placeholder='Fee address'
          callback={(newValue) => setFeeAddress(newValue)}
        />

        <TextField
          type='text'
          label='Fee token'
          placeholder='Fee token'
          callback={(newValue) => setFeeToken(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setFeeDetails, [
                contractAddress,
                totalFeePercent?.trim(),
                specialFeePercent?.trim(),
                feeAddress?.trim(),
                feeToken?.trim()
              ])
            }
          >
            Set fee details
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetFeeDetails;
