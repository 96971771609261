import React from 'react';
import { useLocalStorage } from 'hooks';
import useGetContractCall from 'hooks/useGetContractCall';
import PairViews from './PairViews/PairViews';
import PausePairContract from './PausePairContract/PausePairContract';
import SetFeeOn from './SetFeeOn/SetFeeOn';
import SetFeePercents from './SetFeePercents/SetFeePercents';
import StateActiveNoSwaps from './StateActiveNoSwaps/StateActiveNoSwaps';
import TrustedSwapPair from './TrustedSwapPair/TrustedSwapPair';
import PairWhitelist from './Whitelist/PairWhitelist';

const PairDetails = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const address = params.get('pairAddress');

  const [pairAddress, setPairAddress] = useLocalStorage<string>(
    'selectedPairForDetails',
    address ?? ''
  );

  const { handleContractCall, refreshData } = useGetContractCall(pairAddress);

  return (
    <div className='container mt-5'>
      <h2 className='mb-5'>Pair details</h2>
      <div className='card my-3'>
        <div className='card-body'>
          <p>
            <b>Pair address</b>
          </p>
          <input
            className='form-control'
            type='text'
            placeholder='Pair router contract address'
            value={pairAddress}
            onChange={(event) => setPairAddress(event.target.value)}
          />
        </div>
      </div>
      <PairViews
        refreshData={refreshData}
        contractAddress={pairAddress}
        handleContractCall={handleContractCall}
      />
      <PausePairContract
        contractAddress={pairAddress}
        handleContractCall={handleContractCall}
      />
      <StateActiveNoSwaps
        contractAddress={pairAddress}
        handleContractCall={handleContractCall}
      />
      <PairWhitelist
        contractAddress={pairAddress}
        handleContractCall={handleContractCall}
      />
      <SetFeeOn
        contractAddress={pairAddress}
        handleContractCall={handleContractCall}
      />
      <SetFeePercents
        contractAddress={pairAddress}
        handleContractCall={handleContractCall}
      />
      <TrustedSwapPair
        contractAddress={pairAddress}
        handleContractCall={handleContractCall}
      />
    </div>
  );
};

export default PairDetails;
