import React, { useEffect } from 'react';
import ValueWithLabel from 'components/ValueWithLabel/ValueWithLabel';
import useGetFaucetSettings from 'hooks/useGetFaucetSettings';
import { SectionProps } from 'pages/PairRouter';
import AmountWithLabel from 'pages/PairSettings/PairViews/AmoutWithLabel';

const FaucetViews = ({ contractAddress, refreshData }: SectionProps) => {
  const { isLoading, settings, init } = useGetFaucetSettings(contractAddress);

  useEffect(() => {
    if (!refreshData || isLoading) {
      return;
    }

    init();
  }, [refreshData]);

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Faucet contract state</b>
        </p>

        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <ValueWithLabel
              label={'Contract address:'}
              value={settings?.contractAddress}
            />
            <ValueWithLabel
              label={'Paused:'}
              value={settings?.paused?.toString()}
            />
            <ValueWithLabel
              label={'Registration token:'}
              value={settings?.registrationToken}
            />
            <AmountWithLabel
              label={'Registration fee:'}
              amount={settings?.registrationFee?.toFixed()}
              decimals={settings?.registrationTokenDecimals}
              tokenLabel={settings?.registrationToken}
            />
            <AmountWithLabel
              label={'Accumulated registration fees:'}
              amount={settings?.accumulatedRegistrationFees?.toFixed()}
              decimals={settings?.registrationTokenDecimals}
              tokenLabel={settings?.registrationToken}
            />
            <ValueWithLabel
              label={'Claim fee token:'}
              value={settings?.claimFeeToken}
            />
            <AmountWithLabel
              label={'Accumulated claim fees:'}
              amount={settings?.accumulatedClaimFees?.toFixed()}
              decimals={settings?.claimFeeTokenDecimals}
              tokenLabel={settings?.claimFeeToken}
            />
            <AmountWithLabel
              label={'Claim fee:'}
              amount={settings?.claimFee?.toFixed()}
              decimals={settings?.claimFeeTokenDecimals}
              tokenLabel={settings?.claimFeeToken}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FaucetViews;
