export const dAppName = 'Exrond Admin';

export const apiTimeout = 6000;
export const transactionSize = 15;

export const apiAddress = 'https://api.multiversx.com';
export const chainID = '1';
export const environment = 'mainnet';
export const apiGraphql = 'https://api.exrond.com/graphql';
export const walletConnectV2ProjectId = 'b41e91338c8a2bf995716e2f26460c5e';
