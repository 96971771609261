import {
  Address,
  AddressValue,
  BigUIntValue,
  BytesValue,
  TokenIdentifierValue
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import {
  TransactionResponse,
  getTokenMetadata,
  performTransaction
} from './generalRequests';

export const addPair = async (
  contractAddress: string,
  pairAddress: string,
  firstTokenId: string,
  secondTokenId: string,
  source: string
): Promise<TransactionResponse> => {
  return await performTransaction('addPair', contractAddress, [
    new AddressValue(new Address(pairAddress)),
    new TokenIdentifierValue(firstTokenId),
    new TokenIdentifierValue(secondTokenId),
    new BytesValue(Buffer.from(source))
  ]);
};

export const removePair = async (
  contractAddress: string,
  pairAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('removePair', contractAddress, [
    new AddressValue(new Address(pairAddress))
  ]);
};

export const whitelistOrRemoveWhitelistRouterForAddress = async (
  contractAddress: string,
  address: string,
  method: 'whitelistRouter' | 'removeRouter'
): Promise<TransactionResponse> => {
  return await performTransaction(method, contractAddress, [
    new AddressValue(new Address(address))
  ]);
};

export const setFaucetScAddress = async (
  contractAddress: string,
  address: string
): Promise<TransactionResponse> => {
  return await performTransaction('setFaucetScAddress', contractAddress, [
    new AddressValue(new Address(address))
  ]);
};

export const collectAccumulatedTax = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('collectAccumulatedTax', contractAddress, []);
};

export const whitelistOrBlacklistContractTax = async (
  contractAddress: string,
  address: string,
  method: 'whitelistContractTax' | 'blacklistContractTax'
): Promise<TransactionResponse> => {
  return await performTransaction(method, contractAddress, [
    new AddressValue(new Address(address))
  ]);
};

export const addOrRemoveTrustedSource = async (
  contractAddress: string,
  source: string,
  method: 'addTrustedSource' | 'removeTrustedSource'
): Promise<TransactionResponse> => {
  return await performTransaction(method, contractAddress, [
    new BytesValue(Buffer.from(source))
  ]);
};
export const setTaxPerAction = async (
  contractAddress: string,
  token: string,
  amount: string
): Promise<TransactionResponse> => {
  const tokenDecimals = (await getTokenMetadata(token))?.decimals ?? 0;

  return await performTransaction('setTaxPerAction', contractAddress, [
    new TokenIdentifierValue(token),
    new BigUIntValue(new BigNumber(amount).shiftedBy(tokenDecimals))
  ]);
};
