import React from 'react';
import useGetContractCall from 'hooks/useGetContractCall';
import { useLocalStorage } from 'hooks/useLocalStorage';
import AggregatorContractAddress from 'pages/StakingRouter/SetAggregatorContractAddress/AggregatorContractAddress';
import FaucetViews from './FaucetViews/FaucetViews';
import PauseFaucetContract from './PauseFaucetContract/PauseFaucetContract';
import SetFee from './SetClaimFee/SetClaimFee';
import WithdrawFees from './WithdrawFees/WithdrawFees';

const FaucetPage = () => {
  const [faucetAddress, setFaucetAddress] = useLocalStorage(
    'faucetContract',
    ''
  );

  const { handleContractCall, refreshData } = useGetContractCall(faucetAddress);

  return (
    <div className='container mt-5'>
      <h2 className='mb-5'>Faucet settings</h2>
      <div className='card my-3'>
        <div className='card-body'>
          <p>
            <b>Faucet contract address</b>
          </p>
          <input
            className='form-control'
            type='text'
            placeholder='Faucet contract address'
            value={faucetAddress}
            onChange={(event) => setFaucetAddress(event.target.value)}
          />
        </div>
      </div>
      <FaucetViews
        refreshData={refreshData}
        contractAddress={faucetAddress}
        handleContractCall={handleContractCall}
      />
      <PauseFaucetContract
        contractAddress={faucetAddress}
        handleContractCall={handleContractCall}
      />
      <SetFee
        method='setRegistrationFee'
        contractAddress={faucetAddress}
        handleContractCall={handleContractCall}
      />
      <SetFee
        method='setClaimFee'
        contractAddress={faucetAddress}
        handleContractCall={handleContractCall}
      />
      <WithdrawFees
        method='withdrawRegistrationFees'
        contractAddress={faucetAddress}
        handleContractCall={handleContractCall}
      />
      <WithdrawFees
        method='withdrawClaimFees'
        contractAddress={faucetAddress}
        handleContractCall={handleContractCall}
      />
      <AggregatorContractAddress
        contractAddress={faucetAddress}
        handleContractCall={handleContractCall}
      />
    </div>
  );
};
export default FaucetPage;
