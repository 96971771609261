import { gql } from '@apollo/client';

export const RESUME_FARM = gql`
  mutation resumeFarm($sender: String!, $farmAddress: String!) {
    transactions: resumeFarm(sender: $sender, farmAddress: $farmAddress) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
