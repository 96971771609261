import {
  AbiRegistry,
  Address,
  AddressValue,
  BooleanValue,
  Field,
  Struct,
  TokenIdentifierValue,
  U64Value
} from '@multiversx/sdk-core/out';
import pairAbi from 'abis/pair.abi.json';
import { performTransaction, TransactionResponse } from './generalRequests';

export const setFeeDetails = async (
  contractAddress: string,
  totalFeePercent: string,
  specialFeePercent: string,
  feeAddress: string,
  feeToken: string
): Promise<TransactionResponse> => {
  const registry = AbiRegistry.create(pairAbi.abi as any);
  const feeDetailsType = registry.getStruct('FeeDetails');

  const args = [
    new Struct(feeDetailsType, [
      new Field(
        new U64Value(Number(totalFeePercent) * 1_000),
        'total_fee_percent'
      ),
      new Field(
        new U64Value(Number(specialFeePercent) * 1_000),
        'special_fee_percent'
      ),
      new Field(new AddressValue(new Address(feeAddress)), 'fee_address'),
      new Field(new TokenIdentifierValue(feeToken), 'fee_token')
    ])
  ];

  return await performTransaction('setFeeDetails', contractAddress, args);
};

export const setSpecialFeeSwapPair = async (
  contractAddress: string,
  address: string,
  firstToken: string,
  secondToken: string
): Promise<TransactionResponse> => {
  const args = [
    new AddressValue(new Address(address)),
    new TokenIdentifierValue(firstToken),
    new TokenIdentifierValue(secondToken)
  ];

  return await performTransaction(
    'setSpecialFeeSwapPair',
    contractAddress,
    args
  );
};

export const setPairCreationEnabled = async (
  contractAddress: string,
  enabled: boolean
): Promise<TransactionResponse> => {
  const args = [new BooleanValue(enabled)];

  return await performTransaction(
    'setPairCreationEnabled',
    contractAddress,
    args
  );
};

export const setPairTemplateAddress = async (
  contractAddress: string,
  address: string
): Promise<TransactionResponse> => {
  const args = [new AddressValue(new Address(address))];

  return await performTransaction(
    'setPairTemplateAddress',
    contractAddress,
    args
  );
};

export const removePair = async (
  contractAddress: string,
  firstTokenId: string,
  secondTokenId: string
): Promise<TransactionResponse> => {
  const args = [
    new TokenIdentifierValue(firstTokenId),
    new TokenIdentifierValue(secondTokenId)
  ];

  return await performTransaction('removePair', contractAddress, args);
};
