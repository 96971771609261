import {
  AbiRegistry,
  Address,
  Code,
  CodeMetadata,
  ContractFunction,
  Interaction,
  ResultsParser,
  SmartContract,
  SmartContractAbi,
  TokenPayment,
  TypedOutcomeBundle,
  TypedValue
} from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import aggregatorAbi from 'abis/aggregator.abi.json';
import faucetAbi from 'abis/faucet.abi.json';
import pairAbi from 'abis/pair.abi.json';
import pairRouterAbi from 'abis/router.abi.json';
import { apiAddress, chainID } from 'config';
import { SmartContractProfiler } from './smart-contract-profiler';
export interface TransactionResponse {
  success: boolean;
  error: string;
  sessionId: string | null;
}

const proxy = new ProxyNetworkProvider(apiAddress, {
  timeout: 60000
});
const resultParser = new ResultsParser();

export const getGenericData = async (
  contract: SmartContractProfiler,
  interaction: Interaction
): Promise<TypedOutcomeBundle> => {
  try {
    const queryResponse = await contract.runQuery(
      proxy,
      interaction.buildQuery()
    );
    return resultParser.parseQueryResponse(
      queryResponse,
      interaction.getEndpoint()
    );
  } catch (error: any) {
    console.log('err', error);
    throw error;
  }
};

export const getContractAddress = async (address: string): Promise<string> => {
  try {
    const { data } = await axios.get(`${apiAddress}/transactions/${address}`);
    const rawTopic = data?.logs?.events[0]?.topics[0];
    const rawAddress = Buffer.from(rawTopic, 'base64').toString('hex');
    return Address.fromHex(rawAddress).bech32();
  } catch {
    return '-';
  }
};

export const getTokenMetadata = async (
  tokenId?: string
): Promise<any | undefined> => {
  if (!tokenId || tokenId.length === 0) {
    return undefined;
  }

  try {
    const { data } = await axios.get(`${apiAddress}/tokens/${tokenId}`);
    return data;
  } catch {
    return undefined;
  }
};

const getSmartContract = async (
  contractInterface: string,
  abiJson: string,
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const abiRegistry = AbiRegistry.create(abiJson as any);
  const abi = new SmartContractAbi(abiRegistry, [contractInterface]);

  return new SmartContractProfiler({
    address: new Address(contractAddress),
    abi: abi
  });
};

export const getPairRouterContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'Router',
    pairRouterAbi.abi as any,
    contractAddress
  );
  return contract;
};

export const getFaucetContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'FaucetContract',
    faucetAbi.abi as any,
    contractAddress
  );
  return contract;
};

export const getAggregatorContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'AggregatorContract',
    aggregatorAbi as any,
    contractAddress
  );
  return contract;
};

export const getPairContract = async (
  contractAddress: string
): Promise<SmartContractProfiler> => {
  const contract = await getSmartContract(
    'Pair',
    pairAbi.abi as any,
    contractAddress
  );
  return contract;
};

export const performTransaction = async (
  func: string,
  contract: string,
  args: TypedValue[] = [],
  gasLimit = 10000000
): Promise<TransactionResponse> => {
  try {
    const address = new Address(contract);
    const smartContract = new SmartContract({ address });
    const transaction = smartContract.call({
      func: new ContractFunction(func),
      gasLimit: gasLimit,
      args,
      chainID: chainID
    });

    await refreshAccount();

    const { sessionId, error } = await sendTransactions({
      transactions: transaction.toPlainObject(),
      transactionsDisplayInfo: {
        processingMessage: `Processing ${func} transaction`,
        errorMessage: `An error has occurred during ${func}`,
        successMessage: `${func} transaction successful`
      },
      redirectAfterSign: false,
      minGasLimit: '5000000'
    });

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};

export const deployOrUpgrade = async (
  operation: 'deploy' | 'upgrade',
  address: string | undefined,
  code: Code,
  args: any[],
  gasLimit = 55000000,
  upgradeable?: boolean,
  readable?: boolean,
  payable?: boolean,
  payableBySc?: boolean
): Promise<TransactionResponse> => {
  try {
    const codeMetadata = new CodeMetadata(
      upgradeable ?? true,
      readable ?? true,
      payable ?? false,
      payableBySc ?? true
    );
    const smartContract = new SmartContract({
      address: address ? new Address(address) : undefined
    });
    const deployArguments = {
      code,
      gasLimit: gasLimit,
      codeMetadata,
      initArguments: args,
      value: TokenPayment.egldFromAmount(0),
      chainID: chainID
    };
    const transaction =
      operation === 'deploy'
        ? smartContract.deploy(deployArguments)
        : smartContract.upgrade(deployArguments);

    await refreshAccount();

    const { sessionId, error } = await sendTransactions({
      transactions: transaction.toPlainObject(),
      transactionsDisplayInfo: {
        processingMessage: `Processing ${operation}`,
        errorMessage: `An error has occurred during ${operation}`,
        successMessage: `${operation} successful`
      },
      redirectAfterSign: false,
      minGasLimit: '25000000'
    });

    return { success: error !== undefined, error: error ?? '', sessionId };
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message, sessionId: null };
  }
};
