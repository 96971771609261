import { gql } from '@apollo/client';

export const GET_FARMS_V1 = gql`
  query getAllFarmsV1 {
    farms: getAllFarmsV1 {
      groupId
      farms {
        scAddress
        farmingTokenId
        rewardTokenId
        farmTokenId
        areRewardsLocked
        state
      }
    }
  }
`;

export const GET_FARMS_V2 = gql`
  query getAllFarmsV2 {
    farms: getAllFarmsV2 {
      groupId
      farms {
        scAddress
        farmingTokenId
        rewardTokenId
        farmTokenId
        areRewardsLocked
        state
      }
    }
  }
`;
