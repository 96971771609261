import React, { useState } from 'react';
import { setFeeOn } from 'apiRequests/pairTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

const SetFeeOn = ({ contractAddress, handleContractCall }: SectionProps) => {
  const [address, setAddress] = useState<string>();
  const [token, setToken] = useState<string>();

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set fee on</b>
        </p>

        <TextField
          type='text'
          label='Fee to address'
          placeholder='Fee to address'
          callback={(newValue) => setAddress(newValue)}
        />

        <TextField
          type='text'
          label='Fee token'
          placeholder='Fee token'
          callback={(newValue) => setToken(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setFeeOn, [
                contractAddress,
                address,
                token,
                true
              ])
            }
          >
            Enable
          </button>
          <button
            className='btn btn-danger mr-4'
            onClick={() =>
              handleContractCall(setFeeOn, [
                contractAddress,
                address,
                token,
                false
              ])
            }
          >
            Disable
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetFeeOn;
