import { Interaction } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { AggregatorSettings } from 'types/AggregatorSettings';
import {
  getAggregatorContract,
  getGenericData,
  getTokenMetadata
} from './generalRequests';
import { SmartContractProfiler } from './smart-contract-profiler';
export const getAggregatorSettings = async (
  contractAddress: string
): Promise<AggregatorSettings> => {
  const contract = await getAggregatorContract(contractAddress);
  const taxWhitelist = await getTaxWhitelist(contract);
  const taxPerActionToken = await getTaxPerActionToken(contract);
  const decimals = (await getTokenMetadata(taxPerActionToken))?.decimals ?? 0;
  const taxPerActionValue = await getTaxPerActionValue(contract, decimals);
  const collectedTaxAmount = await getCollectedTaxAmount(contract, decimals);

  return {
    contractAddress,
    taxWhitelist,
    taxPerActionToken,
    taxPerActionValue,
    collectedTaxAmount
  };
};

export const getTaxWhitelist = async (contract: SmartContractProfiler) => {
  const interaction: Interaction = contract.methods.getTaxWhitelist();
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  return firstValue?.toString();
};

export const getTaxPerActionToken = async (contract: SmartContractProfiler) => {
  const interaction: Interaction = contract.methods.getTaxPerActionToken();
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();

  return firstValue;
};

export const getTaxPerActionValue = async (
  contract: SmartContractProfiler,
  decimals: number
) => {
  const interaction: Interaction = contract.methods.getTaxPerActionValue();
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (firstValue === undefined) {
    return undefined;
  }

  return new BigNumber(firstValue).shiftedBy(-decimals);
};

export const getCollectedTaxAmount = async (
  contract: SmartContractProfiler,
  decimals: number
) => {
  const interaction: Interaction = contract.methods.getCollectedTaxAmount();
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (firstValue === undefined) {
    return undefined;
  }

  return new BigNumber(firstValue).shiftedBy(-decimals);
};
