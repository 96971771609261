import React, { useState } from 'react';
import { addPair } from 'apiRequests/aggregatorTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';
export const AddPair = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [pairAddress, setPairAddress] = useState('');
  const [firstToken, setFirstToken] = useState('');
  const [secondToken, setSecondToken] = useState('');
  const [source, setSource] = useState('');

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Add Pair</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='Pair Address'
              placeholder='Pair Address'
              callback={(newValue) => setPairAddress(newValue)}
            />
            <TextField
              type='text'
              label='First token'
              placeholder='First token'
              callback={(newValue) => setFirstToken(newValue)}
            />
            <TextField
              type='text'
              label='Second token'
              placeholder='Second token'
              callback={(newValue) => setSecondToken(newValue)}
            />
            <TextField
              type='text'
              label='Source'
              placeholder='Source'
              callback={(newValue) => setSource(newValue)}
            />

            <div className='mt-1'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(addPair, [
                    contractAddress,
                    pairAddress,
                    firstToken,
                    secondToken,
                    source
                  ])
                }
              >
                Add Pair
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
