import React from 'react';

import { Address } from '@multiversx/sdk-core/out';
import {
  AbiRegistry,
  AddressValue,
  Field,
  Struct,
  TokenIdentifierValue,
  U64Value
} from '@multiversx/sdk-core/out/smartcontracts/typesystem';
import pairAbi from 'abis/pair.abi.json';
import useWasmCode from 'hooks/useWasmCode';
import { DeploySectionProps } from '../DeploySectionProps';

const DeployDummyPairContract = ({ handleTransaction }: DeploySectionProps) => {
  const { code, onChange } = useWasmCode();

  const handleSubmission = async () => {
    if (!code) {
      return;
    }

    handleTransaction('deploy', undefined, code, getDummyArgs(), 150000000);
  };

  const getDummyArgs = () => {
    const dummyAddress =
      'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u';
    const dummyTokenA = 'DUMMY-4a5d83';
    const dummyTokenB = 'DUMMY-cca2c2';
    const fee = 0;

    const registry = AbiRegistry.create(pairAbi.abi as any);
    const feeDetailsType = registry.getStruct('FeeDetails');
    const trustedSwapPairType = registry.getStruct('TrustedSwapPair');

    const args = [
      new TokenIdentifierValue(dummyTokenA),
      new TokenIdentifierValue(dummyTokenB),
      new AddressValue(new Address(dummyAddress)),
      new AddressValue(new Address(dummyAddress)),
      new AddressValue(new Address(dummyAddress)),
      new Struct(feeDetailsType, [
        new Field(new U64Value(fee), 'total_fee_percent'),
        new Field(new U64Value(fee), 'special_fee_percent'),
        new Field(new AddressValue(new Address(dummyAddress)), 'fee_address'),
        new Field(new TokenIdentifierValue(dummyTokenA), 'fee_token')
      ]),
      new Struct(trustedSwapPairType, [
        new Field(new AddressValue(new Address(dummyAddress)), 'address'),
        new Field(new TokenIdentifierValue(dummyTokenA), 'first_token'),
        new Field(new TokenIdentifierValue(dummyTokenB), 'second_token')
      ])
    ];

    return args;
  };

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Deploy dummy pair contract</b>
        </p>

        <input type='file' name='file' onChange={onChange} />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() => handleSubmission()}
          >
            Deploy
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeployDummyPairContract;
