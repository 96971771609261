import { useMutation } from '@apollo/client';
import { ADD_TOKEN_TO_FAVORITES } from 'apiRequests/mutations/addTokenToFavorites';

export const ACCESS_TOKEN =
  'admin-exr-0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852';

const useAddTokenToFavorites = () => {
  const [addTokenToFavorites, { data, loading }] = useMutation(
    ADD_TOKEN_TO_FAVORITES,
    {
      context: {
        headers: {
          Authorization: ACCESS_TOKEN
        }
      }
    }
  );

  const performMutation = (tokenId: string, position: number) => {
    addTokenToFavorites({
      variables: {
        tokenId,
        position
      }
    });
  };

  return {
    performMutation,
    data,
    loading
  };
};

export { useAddTokenToFavorites };
