import React from 'react';
import { withdrawFaucetFees } from 'apiRequests/faucetTransactions';
import { SectionProps } from 'pages/PairRouter';

export interface WithdrawFeesProps extends SectionProps {
  method: 'withdrawRegistrationFees' | 'withdrawClaimFees';
}

const WithdrawFees = ({
  contractAddress,
  method,
  handleContractCall
}: WithdrawFeesProps) => {
  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>
            {method === 'withdrawRegistrationFees'
              ? 'Withdraw registration fees'
              : 'Withdraw claim fees'}
          </b>
        </p>

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(withdrawFaucetFees, [
                contractAddress,
                'withdrawRegistrationFees'
              ])
            }
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawFees;
