import React from 'react';
import { SwapTokensPair } from 'types';
import TrustedSwapPairItem from './TrustedSwapPairItem';

export interface TrustedSwapPairListProps {
  pairs?: SwapTokensPair[];
}

const TrustedSwapPairList = ({ pairs }: TrustedSwapPairListProps) => {
  const hasPairs = () => {
    return pairs !== undefined && pairs.length > 0;
  };

  return (
    <div>
      <p>
        <b>Trusted swap pairs:</b>
      </p>
      <div className='ml-4'>
        {hasPairs() ? (
          <div>There are no trusted swap pairs</div>
        ) : (
          pairs?.map((pair) => (
            <div className='d-flex flex-column' key={pair.address}>
              <TrustedSwapPairItem pair={pair} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TrustedSwapPairList;
