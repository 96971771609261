import React, { useEffect } from 'react';
import ValueWithLabel from 'components/ValueWithLabel/ValueWithLabel';
import useGetPairRouterSettings from 'hooks/useGetPairRouterSettings';
import { formatPercent } from 'utils/pairUtils';
import { SectionProps } from '../SectionProps';

const PairRouterViews = ({ contractAddress, refreshData }: SectionProps) => {
  const { isLoading, settings, init } =
    useGetPairRouterSettings(contractAddress);

  useEffect(() => {
    if (!refreshData || isLoading) {
      return;
    }

    init();
  }, [refreshData]);

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Pair router contract state</b>
        </p>

        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <ValueWithLabel
              label={'Contract address:'}
              value={settings?.contractAddress}
            />
            <ValueWithLabel
              label={'Pair template address:'}
              value={settings?.pairTemplateAddress}
            />
            <div>
              <p>
                <b>Fee details:</b>
              </p>
              <div className='ml-4'>
                <ValueWithLabel
                  label={'Total fee percent:'}
                  value={formatPercent(settings?.feeDetails?.totalFeePercent)}
                />
                <ValueWithLabel
                  label={'Special fee percent:'}
                  value={formatPercent(settings?.feeDetails?.specialFeePercent)}
                />
                <ValueWithLabel
                  label={'Fee address:'}
                  value={settings?.feeDetails?.feeAddress}
                />
                <ValueWithLabel
                  label={'Fee token:'}
                  value={settings?.feeDetails?.feeToken}
                />
              </div>
            </div>
            <div>
              <p>
                <b>Special fee swap pair:</b>
              </p>
              <div className='ml-4'>
                <ValueWithLabel
                  label={'Address:'}
                  value={settings?.specialFeeSwapPair?.address}
                />
                <ValueWithLabel
                  label={'First token:'}
                  value={settings?.specialFeeSwapPair?.firstToken}
                />
                <ValueWithLabel
                  label={'Second token:'}
                  value={settings?.specialFeeSwapPair?.secondToken}
                />
              </div>
            </div>
            <ValueWithLabel
              label={'Pair creation enabled:'}
              value={settings?.pairCreationEnabled ? 'true' : 'false'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PairRouterViews;
