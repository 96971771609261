import React from 'react';
import { useLocalStorage } from 'hooks';
import PairsList from './PairsList/PairsList';

const PairsPage = () => {
  const [pairRouterAddress, setPairRouterAddress] = useLocalStorage(
    'pairRouterContract',
    ''
  );

  return (
    <div className='container mt-5'>
      <h2 className='mb-5'>All pairs</h2>
      <div className='card my-3'>
        <div className='card-body'>
          <p>
            <b>Pair router contract address</b>
          </p>
          <input
            className='form-control'
            type='text'
            placeholder='Pair router contract address'
            value={pairRouterAddress}
            onChange={(event) => setPairRouterAddress(event.target.value)}
          />
        </div>
      </div>
      <PairsList routerAddress={pairRouterAddress} />
    </div>
  );
};
export default PairsPage;
