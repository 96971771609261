import * as React from 'react';
import { setDivisionSafetyConstant } from 'apiRequests/stakingRouterTransactions';
import { SectionProps } from 'pages/PairRouter';

const SetDivisionSafetyConstant = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Set division safety constant</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <div>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(setDivisionSafetyConstant, [
                    contractAddress
                  ])
                }
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetDivisionSafetyConstant;
