import { dAppName } from 'config';
import { Home } from 'pages';
import { AggregatorPage } from 'pages/Aggregator';
import DeployPage from 'pages/Deploy/DeployPage';
import FarmsPage from 'pages/Farms/FarmsPage';
import FaucetPage from 'pages/Faucet/FaucetPage';
import PairRouterPage from 'pages/PairRouter/PairRouterPage';
import PairsPage from 'pages/Pairs/PairsPage';
import PairDetails from 'pages/PairSettings/PairSettingsPage';
import StakingRouterPage from 'pages/StakingRouter/StakingRouter';
import { withPageTitle } from './components/PageTitle';

export const routeNames = {
  home: '/',
  unlock: '/unlock',
  deploy: '/deploy',
  pairRouter: '/pairRouter',
  pairs: '/pairs',
  pairDetails: '/pairDetails',
  stakingRouter: '/stakingRouter',
  faucet: '/faucet',
  farms: '/farms',
  aggregator: '/aggregator'
};

export const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.deploy,
    title: 'Deploy',
    component: DeployPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.pairRouter,
    title: 'Pair router',
    component: PairRouterPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.pairs,
    title: 'All pairs',
    component: PairsPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.pairDetails,
    title: 'Pair details',
    component: PairDetails,
    authenticatedRoute: true
  },
  {
    path: routeNames.stakingRouter,
    title: 'Staking router',
    component: StakingRouterPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.faucet,
    title: 'Faucet',
    component: FaucetPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.farms,
    title: 'Farms',
    component: FarmsPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.aggregator,
    title: 'Aggregator',
    component: AggregatorPage,
    authenticatedRoute: true
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • Elrond ${dAppName}`
    : `Elrond ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
