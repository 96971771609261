export class GroupFarm {
  groupId = '';
  farms: FarmDataStruct[] = [];

  constructor(init?: Partial<GroupFarm>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): GroupFarm {
    return new GroupFarm({
      groupId: response.groupId,
      farms:
        response.farms?.map((farm: any) => FarmDataStruct.fromResponse(farm)) ??
        []
    });
  }
}

export class FarmDataStruct {
  scAddress!: string;
  farmingTokenId!: string;
  rewardTokenId!: string;
  areRewardsLocked!: string;
  farmTokenId = '';
  state = FarmState.INACTIVE;

  constructor(init?: Partial<FarmDataStruct>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): FarmDataStruct {
    return new FarmDataStruct({
      scAddress: response.scAddress,
      farmingTokenId: response.farmingTokenId,
      rewardTokenId: response.rewardTokenId,
      areRewardsLocked: response.areRewardsLocked,
      farmTokenId: response.farmTokenId,
      state: response.state as FarmState
    });
  }
}

export enum FarmState {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}
