import { Interaction } from '@multiversx/sdk-core/out';
import { BigNumber } from 'bignumber.js';
import { FaucetSettings } from 'types/FaucetSettings';
import {
  getFaucetContract,
  getGenericData,
  getTokenMetadata
} from './generalRequests';
import { SmartContractProfiler } from './smart-contract-profiler';

export const getFaucetSettings = async (
  contractAddress: string
): Promise<FaucetSettings> => {
  const contract = await getFaucetContract(contractAddress);

  const paused = await getPauseState(contract);
  const registrationToken = await getRegistrationToken(contract);
  const registrationTokenDecimals =
    (await getTokenMetadata(registrationToken))?.decimals ?? 0;
  const registrationFee = await getRegistrationFee(contract);
  const accumulatedRegistrationFees = await getAccumulatedRegistrationFees(
    contract
  );
  const accumulatedClaimFees = await getAccumulatedClaimFees(contract);
  const claimFeeToken = await getClaimFeeToken(contract);
  const claimFeeTokenDecimals =
    (await getTokenMetadata(claimFeeToken))?.decimals ?? 0;
  const claimFee = await getClaimFee(contract);

  return {
    contractAddress,
    paused,
    registrationToken,
    registrationTokenDecimals,
    registrationFee,
    accumulatedRegistrationFees,
    accumulatedClaimFees,
    claimFeeToken,
    claimFeeTokenDecimals,
    claimFee
  };
};

export const getPauseState = async (
  contract: SmartContractProfiler
): Promise<boolean> => {
  const interaction: Interaction = contract.methods.isPaused([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf();
};

export const getRegistrationToken = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getRegistrationToken([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  return firstValue?.toString();
};

export const getRegistrationFee = async (
  contract: SmartContractProfiler
): Promise<BigNumber | undefined> => {
  const interaction: Interaction = contract.methods.getRegistrationFee([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (firstValue === undefined) {
    return undefined;
  }

  return new BigNumber(firstValue);
};

export const getAccumulatedRegistrationFees = async (
  contract: SmartContractProfiler
): Promise<BigNumber | undefined> => {
  const interaction: Interaction =
    contract.methods.getAccumulatedRegistrationFees([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (firstValue === undefined) {
    return undefined;
  }

  return new BigNumber(firstValue);
};

export const getAccumulatedClaimFees = async (
  contract: SmartContractProfiler
): Promise<BigNumber | undefined> => {
  const interaction: Interaction = contract.methods.getAccumulatedClaimFees([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (firstValue === undefined) {
    return undefined;
  }

  return new BigNumber(firstValue);
};

export const getClaimFeeToken = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getClaimFeeToken([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  return firstValue?.toString();
};

export const getClaimFee = async (
  contract: SmartContractProfiler
): Promise<BigNumber | undefined> => {
  const interaction: Interaction = contract.methods.getClaimFee([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (firstValue === undefined) {
    return undefined;
  }

  return new BigNumber(firstValue);
};
