import { gql } from '@apollo/client';

export const RESUME_ALL_FARMS = gql`
  mutation resumeAllFarms($sender: String!) {
    transactions: resumeAllFarms(sender: $sender) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
