import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import {
  UPGRADE_ALL_FARMS_V1,
  UPGRADE_ALL_FARMS_V2
} from 'apiRequests/mutations/upgradeAllFarms';
import { FarmStakingVersion } from 'types/FarmVersion';
import useGetAndSendTransactions from './useGetAndSendTransactions';

const useUpgradeAllFarms = (version: FarmStakingVersion) => {
  const { address } = useGetAccount();

  const displayInfo = {
    processingMessage: 'Upgrade all farms',
    errorMessage: 'An error has occurred while upgrading all farms',
    successMessage: 'Farms upgraded'
  };

  const [mutateUpgradeAllFarms] = useGetAndSendTransactions(
    version === FarmStakingVersion.V1
      ? UPGRADE_ALL_FARMS_V1
      : UPGRADE_ALL_FARMS_V2,
    displayInfo
  );

  const upgradeAllFarms = () => {
    mutateUpgradeAllFarms({
      variables: {
        sender: address
      }
    });
  };

  return upgradeAllFarms;
};

export default useUpgradeAllFarms;
