import React from 'react';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as ElrondLogo } from './../../../assets/img/elrond.svg';

export const Navbar = () => {
  const isLoggedIn = useGetIsLoggedIn();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  return (
    <BsNavbar className='bg-white border-bottom px-4 py-3'>
      <div className='container-fluid'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={isLoggedIn ? routeNames.deploy : routeNames.home}
        >
          <ElrondLogo className='elrond-logo' />
          <span className='dapp-name text-muted'>{dAppName}</span>
        </Link>

        <div className='d-flex align-items-center justify-content-center flex-grow-1'>
          <Nav className='me-auto'>
            <NavItem>
              <Link to={routeNames.deploy} className='nav-link'>
                Deploy
              </Link>
            </NavItem>
            <NavItem>
              <Link to={routeNames.pairRouter} className='nav-link'>
                Pair Router
              </Link>
            </NavItem>
            <NavItem>
              <Link to={routeNames.pairs} className='nav-link'>
                Pairs
              </Link>
            </NavItem>
            <NavItem>
              <Link to={routeNames.pairDetails} className='nav-link'>
                Pair details
              </Link>
            </NavItem>
            <NavItem>
              <Link to={routeNames.stakingRouter} className='nav-link'>
                Staking router
              </Link>
            </NavItem>
            <NavItem>
              <Link to={routeNames.faucet} className='nav-link'>
                Faucet
              </Link>
            </NavItem>
            <NavItem>
              <Link to={routeNames.farms} className='nav-link'>
                Farms
              </Link>
            </NavItem>
            <NavItem>
              <Link to={routeNames.aggregator} className='nav-link'>
                Aggregator
              </Link>
            </NavItem>
          </Nav>
        </div>

        <Nav className='ml-auto'>
          {isLoggedIn && (
            <NavItem>
              <button className='btn btn-link' onClick={handleLogout}>
                Close
              </button>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};
