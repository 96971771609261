import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import {
  UPGRADE_FARM_V1,
  UPGRADE_FARM_V2
} from 'apiRequests/mutations/upgradeFarm';
import { FarmStakingVersion } from 'types/FarmVersion';
import useGetAndSendTransactions from './useGetAndSendTransactions';

const useUpgradeFarm = (version: FarmStakingVersion) => {
  const { address } = useGetAccount();

  const displayInfo = {
    processingMessage: 'Upgrade farm',
    errorMessage: 'An error has occurred while upgrading farm',
    successMessage: 'Farm upgraded'
  };

  const [mutateUpgradeFarm] = useGetAndSendTransactions(
    version === FarmStakingVersion.V1 ? UPGRADE_FARM_V1 : UPGRADE_FARM_V2,
    displayInfo
  );

  const upgradeFarm = (farmAddress: string, groupId: string) => {
    mutateUpgradeFarm({
      variables: {
        sender: address,
        farmAddress,
        groupId
      }
    });
  };

  return upgradeFarm;
};

export default useUpgradeFarm;
