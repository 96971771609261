import React, { useState } from 'react';
import { setTaxPerAction } from 'apiRequests/aggregatorTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter/SectionProps';

const SetTaxPerAction = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [token, setToken] = useState<string>();
  const [amount, setAmount] = useState<string>();

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Set tax per action</h2>
        <div className='card my-3'>
          <div className='card-body'>
            <TextField
              type='text'
              label='Fee token'
              placeholder='Fee token'
              callback={(newValue) => setToken(newValue)}
            />

            <TextField
              type='text'
              label='Fee'
              placeholder='Fee'
              callback={(newValue) => setAmount(newValue)}
            />

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(setTaxPerAction, [
                    contractAddress,
                    token,
                    amount
                  ])
                }
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetTaxPerAction;
