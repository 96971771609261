import React from 'react';
import { SwapTokensPair } from 'types';

export interface TrustedSwapPairItemProps {
  pair: SwapTokensPair;
}

const TrustedSwapPairItem = ({ pair }: TrustedSwapPairItemProps) => {
  return (
    <>
      <span>{pair.address}</span>
      <span>{pair.tokenPair?.firstToken}</span>
      <span>{pair.tokenPair?.secondToken}</span>
    </>
  );
};

export default TrustedSwapPairItem;
