import { useMutation } from '@apollo/client';
import { REMOVE_TOKEN_FROM_FAVORITES } from 'apiRequests/mutations/removeTokenFromFavorites';

export const ACCESS_TOKEN =
  'admin-exr-0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852';

const useRemoveTokenFromFavorites = () => {
  const [remove, { data, loading }] = useMutation(REMOVE_TOKEN_FROM_FAVORITES, {
    context: {
      headers: {
        Authorization: ACCESS_TOKEN
      }
    }
  });

  const performMutation = (tokenId: string) => {
    remove({
      variables: {
        tokenId
      }
    });
  };

  return {
    performMutation,
    data,
    loading
  };
};

export { useRemoveTokenFromFavorites };
