import { TokenIdentifierValue } from '@multiversx/sdk-core/out';
import { BigUIntValue } from '@multiversx/sdk-core/out/smartcontracts';
import BigNumber from 'bignumber.js';
import {
  getTokenMetadata,
  performTransaction,
  TransactionResponse
} from './generalRequests';

export const setFaucetFee = async (
  contractAddress: string,
  method: 'setRegistrationFee' | 'setClaimFee',
  token: string,
  amount: string
): Promise<TransactionResponse> => {
  const tokenDecimals = (await getTokenMetadata(token))?.decimals ?? 0;

  return await performTransaction(method, contractAddress, [
    new TokenIdentifierValue(token),
    new BigUIntValue(new BigNumber(amount).shiftedBy(tokenDecimals))
  ]);
};

export const withdrawFaucetFees = async (
  contractAddress: string,
  method: 'withdrawRegistrationFees' | 'withdrawClaimFees'
): Promise<TransactionResponse> => {
  return await performTransaction(method, contractAddress, []);
};
