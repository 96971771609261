import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { PAUSE_ALL_FARMS } from 'apiRequests/mutations/pauseAllFarms';
import useGetAndSendTransactions from './useGetAndSendTransactions';

const usePauseAllFarms = () => {
  const { address } = useGetAccount();

  const displayInfo = {
    processingMessage: 'Pause all farms',
    errorMessage: 'An error has occurred while pausing all farms',
    successMessage: 'Farms paused'
  };

  const [mutatePauseAllFarms] = useGetAndSendTransactions(
    PAUSE_ALL_FARMS,
    displayInfo
  );

  const pauseAllFarms = () => {
    mutatePauseAllFarms({
      variables: {
        sender: address
      }
    });
  };

  return pauseAllFarms;
};

export default usePauseAllFarms;
