import { Interaction } from '@multiversx/sdk-core/out';
import { BigNumber } from 'bignumber.js';
import {
  FeeDetails,
  PairContractMetadata,
  RouterSettings,
  SpecialFeeSwapPair
} from 'types';
import { getGenericData, getPairRouterContract } from './generalRequests';
import { SmartContractProfiler } from './smart-contract-profiler';

export const getPairRouterSettings = async (
  contractAddress: string
): Promise<RouterSettings> => {
  const contract = await getPairRouterContract(contractAddress);

  const pairTemplateAddress = await getPairTemplateAddress(contract);
  const feeDetails = await getFeeDetails(contract);
  const specialFeeSwapPair = await getSpecialFeeSwapPair(contract);
  const pairCreationEnabled = await getPairCreationEnabled(contract);

  return {
    contractAddress,
    pairTemplateAddress: pairTemplateAddress?.toString(),
    feeDetails,
    specialFeeSwapPair,
    pairCreationEnabled
  };
};

export const getPairTemplateAddress = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getPairTemplateAddress([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf().toString();
};

export const getFeeDetails = async (
  contract: SmartContractProfiler
): Promise<FeeDetails | undefined> => {
  const interaction: Interaction = contract.methods.getFeeDetails([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();

  if (!firstValue) {
    return undefined;
  }

  return {
    totalFeePercent: new BigNumber(firstValue.total_fee_percent).toNumber(),
    specialFeePercent: new BigNumber(firstValue.special_fee_percent).toNumber(),
    feeAddress: firstValue.fee_address.toString(),
    feeToken: firstValue.fee_token
  } as FeeDetails;
};

export const getSpecialFeeSwapPair = async (
  contract: SmartContractProfiler
): Promise<SpecialFeeSwapPair | undefined> => {
  const interaction: Interaction = contract.methods.getSpecialFeeSwapPair([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();

  if (!firstValue) {
    return undefined;
  }

  return {
    address: firstValue.address.toString(),
    firstToken: firstValue.first_token,
    secondToken: firstValue.second_token
  } as SpecialFeeSwapPair;
};

export const getPairCreationEnabled = async (
  contract: SmartContractProfiler
): Promise<boolean> => {
  const interaction: Interaction = contract.methods.getPairCreationEnabled([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf() ?? false;
};

export const getAllPairsMetadata = async (
  contractAddress: string
): Promise<PairContractMetadata[]> => {
  const contract = await getPairRouterContract(contractAddress);

  const interaction: Interaction = contract.methods.getAllPairContractMetadata(
    []
  );
  const response = await getGenericData(contract, interaction);
  return (
    response.firstValue
      ?.valueOf()
      ?.filter((obj: any) => obj)
      ?.map((obj: any) => {
        return {
          address: obj.address.toString(),
          firstTokenId: obj.first_token_id,
          secondTokenId: obj.second_token_id
        } as PairContractMetadata;
      }) ?? []
  );
};
