import * as React from 'react';
import { useState } from 'react';
import { setAggregatorContractAddressPairRouter } from 'apiRequests/stakingRouterTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

const AggregatorContractAddress = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [address, setAddress] = useState('');

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set aggregator contract address</b>
        </p>

        <TextField
          type='text'
          label='Address'
          placeholder='Address'
          callback={(newValue) => setAddress(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setAggregatorContractAddressPairRouter, [
                contractAddress,
                address
              ])
            }
          >
            Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default AggregatorContractAddress;
