import React, { useState } from 'react';
import { setFaucetFee } from 'apiRequests/faucetTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

export interface SetFeeProps extends SectionProps {
  method: 'setRegistrationFee' | 'setClaimFee';
}

const SetFee = ({
  contractAddress,
  method,
  handleContractCall
}: SetFeeProps) => {
  const [token, setToken] = useState<string>();
  const [amount, setAmount] = useState<string>();

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>
            {method === 'setClaimFee'
              ? 'Set claim fee'
              : 'Set registration fee'}
          </b>
        </p>

        <TextField
          type='text'
          label='Fee token'
          placeholder='Fee token'
          callback={(newValue) => setToken(newValue)}
        />

        <TextField
          type='text'
          label='Fee'
          placeholder='Fee'
          callback={(newValue) => setAmount(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setFaucetFee, [
                contractAddress,
                method,
                token,
                amount
              ])
            }
          >
            Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetFee;
