import React from 'react';
import useWasmCode from 'hooks/useWasmCode';
import { DeploySectionProps } from '../DeploySectionProps';
export const DeployAggregatorContract = ({
  handleTransaction
}: DeploySectionProps) => {
  const { code, onChange } = useWasmCode();

  const handleSubmission = async (operation: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    handleTransaction(operation, undefined, code, [], 150000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Aggregator contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <input type='file' name='file' onChange={onChange} />

            <div className='mt-2'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission('deploy')}
              >
                Deploy
              </button>
            </div>
            <div>
              <pre className='pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
