import React, { useState } from 'react';
import {
  addTrustedSwapPair,
  removeTrustedSwapPair
} from 'apiRequests/pairTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

const TrustedSwapPair = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [pairAddress, setPairAddress] = useState<string>();
  const [firstTokenId, setFirstTokenId] = useState<string>();
  const [secondTokenId, setSecondTokenId] = useState<string>();

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set trusted swap pair</b>
        </p>

        <TextField
          type='text'
          label='Pair address (or empty when removing)'
          placeholder='Pair address'
          callback={(newValue) => setPairAddress(newValue)}
        />

        <TextField
          type='text'
          label='First token id'
          placeholder='First token id'
          callback={(newValue) => setFirstTokenId(newValue)}
        />

        <TextField
          type='text'
          label='Second token id'
          placeholder='Second token id'
          callback={(newValue) => setSecondTokenId(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(addTrustedSwapPair, [
                contractAddress,
                pairAddress,
                firstTokenId,
                secondTokenId
              ])
            }
          >
            Add
          </button>
          <button
            className='btn btn-danger mr-4'
            onClick={() =>
              handleContractCall(removeTrustedSwapPair, [
                contractAddress,
                firstTokenId,
                secondTokenId
              ])
            }
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrustedSwapPair;
