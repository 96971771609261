import { useEffect, useState } from 'react';
import { getFaucetSettings } from 'apiRequests/faucetViews';
import { FaucetSettings } from 'types/FaucetSettings';

const useGetFaucetSettings = (contractAddress: string | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<FaucetSettings>();

  const init = async () => {
    if (!contractAddress || contractAddress === '') {
      return;
    }

    try {
      setIsLoading(true);

      const _stats = await getFaucetSettings(contractAddress);
      setSettings(_stats);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [contractAddress]);

  return { isLoading, settings, init };
};

export default useGetFaucetSettings;
