import React from 'react';

import {
  BigUIntValue,
  BooleanValue,
  BytesValue,
  U64Value
} from '@multiversx/sdk-core/out/smartcontracts/typesystem';
import BigNumber from 'bignumber.js';
import useWasmCode from 'hooks/useWasmCode';
import { DeploySectionProps } from '../DeploySectionProps';

const DeployNftFarmStakingContract = ({
  handleTransaction
}: DeploySectionProps) => {
  const { code, onChange } = useWasmCode();

  const handleSubmission = async () => {
    if (!code) {
      return;
    }

    const farmingTokenId = 'DUMMY-4a5d83';
    const rewardTokenId = 'DUMMY-cca2c2';
    const divisionSafetyConstant = 1;
    const minUnbondEpochs = 3;
    const areRewardsLocked = false;
    const maxStakedNfts = 100;
    const address = '';

    const args = [
      new BytesValue(Buffer.from(farmingTokenId)),
      new BytesValue(Buffer.from(rewardTokenId)),
      new BigUIntValue(new BigNumber(divisionSafetyConstant)),
      new BigUIntValue(new BigNumber(minUnbondEpochs)),
      new BooleanValue(areRewardsLocked),
      new U64Value(new BigNumber(maxStakedNfts))
    ];

    handleTransaction('deploy', address, code, args, 150000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy NFT Farm Staking contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <p>Deploy arguments:</p>
            <input type='file' name='file' onChange={onChange} />

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission()}
              >
                Deploy
              </button>
            </div>
            <div>
              <pre className='mt-2 pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployNftFarmStakingContract;
