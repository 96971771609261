import { Interaction, Address } from '@multiversx/sdk-core/out';
import { BigNumber } from 'bignumber.js';
import { SwapTokensPair } from 'types';
import { PairSettings } from 'types/PairSettings';
import {
  getGenericData,
  getPairContract,
  getTokenMetadata
} from './generalRequests';
import { SmartContractProfiler } from './smart-contract-profiler';

export const getPairSettings = async (
  contractAddress: string
): Promise<PairSettings> => {
  const contract = await getPairContract(contractAddress);

  const firstTokenId = await getFirstTokenId(contract);
  const firstTokenDecimals =
    (await getTokenMetadata(firstTokenId))?.decimals ?? 0;

  const secondTokenId = await getSecondTokenId(contract);
  const secondTokenDecimals =
    (await getTokenMetadata(secondTokenId))?.decimals ?? 0;

  const lpTokenId = await getLpTokenIdentifier(contract);
  const lpTokenDecimals = (await getTokenMetadata(lpTokenId))?.decimals ?? 0;

  const reservedAndTotalSupply = await getReservesAndTotalSupply(contract);
  const firstTokenReserve = reservedAndTotalSupply.firstTokenReserve;
  const secondTokenReserve = reservedAndTotalSupply.secondTokenReserve;
  const totalSupply = reservedAndTotalSupply.totalSupply;
  const trustedSwapPairs = await getTrustedSwapPairs(contract);
  const whitelistManagedAddresses = await getWhitelistedManagedAddresses(
    contract
  );
  const state = await getPairContractState(contract);
  const feeState = await getFeeState(contract);
  const totalFeePercent = await getTotalFeePercent(contract);
  const specialFeePercent = await getSpecialFee(contract);

  return {
    firstTokenId,
    secondTokenId,
    firstTokenReserve,
    secondTokenReserve,
    lpTokenId,
    totalSupply,
    trustedSwapPairs,
    whitelistManagedAddresses,
    state,
    feeState,
    totalFeePercent,
    specialFeePercent,
    firstTokenDecimals,
    secondTokenDecimals,
    lpTokenDecimals
  } as PairSettings;
};

export const getFirstTokenId = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getFirstTokenId([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf();
};

export const getSecondTokenId = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getSecondTokenId([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf();
};

export const getReservesAndTotalSupply = async (
  contract: SmartContractProfiler
): Promise<{
  firstTokenReserve: BigNumber | undefined;
  secondTokenReserve: BigNumber | undefined;
  totalSupply: BigNumber | undefined;
}> => {
  const interaction: Interaction =
    contract.methodsExplicit.getReservesAndTotalSupply([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response?.firstValue?.valueOf();
  const secondValue = response?.secondValue?.valueOf();
  const thirdValue = response?.thirdValue?.valueOf();

  const firstTokenReserve = firstValue ? new BigNumber(firstValue) : undefined;
  const secondTokenReserve = secondValue
    ? new BigNumber(secondValue)
    : undefined;
  const totalSupply = thirdValue ? new BigNumber(thirdValue) : undefined;

  return {
    firstTokenReserve,
    secondTokenReserve,
    totalSupply
  };
};

export const getLpTokenIdentifier = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getLpTokenIdentifier([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf();
};

export const getTrustedSwapPairs = async (
  contract: SmartContractProfiler
): Promise<SwapTokensPair[]> => {
  const interaction: Interaction = contract.methods.getTrustedSwapPairs([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (!firstValue) {
    return [];
  }

  return firstValue.map((value: any) => {
    const address = value.field1;
    const pair = value.field0;
    return {
      address: new Address(address).toString(),
      tokenPair: {
        firstToken: pair.firstToken,
        secondToken: pair.secondToken
      }
    } as SwapTokensPair;
  });
};

export const getWhitelistedManagedAddresses = async (
  contract: SmartContractProfiler
): Promise<string[] | undefined> => {
  const interaction: Interaction =
    contract.methods.getWhitelistedManagedAddresses([]);
  const response = await getGenericData(contract, interaction);
  return (
    response.firstValue
      ?.valueOf()
      ?.map((address: Address) => address.toString()) ?? []
  );
};

export const getPairContractState = async (
  contract: SmartContractProfiler
): Promise<string | undefined> => {
  const interaction: Interaction = contract.methods.getState([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf()?.name;
};

export const getFeeState = async (
  contract: SmartContractProfiler
): Promise<boolean> => {
  const interaction: Interaction = contract.methods.getFeeState([]);
  const response = await getGenericData(contract, interaction);
  return response.firstValue?.valueOf() ?? false;
};

export const getTotalFeePercent = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getTotalFeePercent([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (!firstValue) {
    return undefined;
  }
  return new BigNumber(firstValue).toNumber();
};

export const getSpecialFee = async (
  contract: SmartContractProfiler
): Promise<number | undefined> => {
  const interaction: Interaction = contract.methods.getSpecialFee([]);
  const response = await getGenericData(contract, interaction);
  const firstValue = response.firstValue?.valueOf();
  if (!firstValue) {
    return undefined;
  }
  return new BigNumber(firstValue).toNumber();
};
