import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removePair, TransactionResponse } from 'apiRequests';
import { PairContractMetadata } from 'types';
import PairInfo from './PairInfo';

export interface PairProps {
  routerAddress: string;
  pairContractMetadata: PairContractMetadata;
  handleContractCall: (
    fetcher: (...payload: any) => Promise<TransactionResponse>,
    payload: any[]
  ) => void;
}

const Pair = ({
  routerAddress,
  pairContractMetadata,
  handleContractCall
}: PairProps) => {
  const navigate = useNavigate();

  const goToDetails = (address: string) =>
    navigate({
      pathname: '/pairDetails',
      search: `?pairAddress=${address}`
    });

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <div className='d-flex flex-column'>
          <PairInfo
            title={'Pair address:'}
            description={pairContractMetadata.address}
          />
          <PairInfo
            title={'First token id:'}
            description={pairContractMetadata.firstTokenId}
          />
          <PairInfo
            title={'Second token id:'}
            description={pairContractMetadata.secondTokenId}
          />
          <div className='mt-3 d-flex justify-content-between'>
            <button
              className='btn btn-primary'
              onClick={() => goToDetails(pairContractMetadata.address)}
            >
              Details
            </button>
            <button
              className='btn btn-danger'
              onClick={() =>
                handleContractCall(removePair, [
                  routerAddress,
                  pairContractMetadata.firstTokenId ?? '',
                  pairContractMetadata.secondTokenId ?? ''
                ])
              }
            >
              Remove pair
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pair;
