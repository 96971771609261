import React, { useState } from 'react';
import { whitelistOrBlacklistContractTax } from 'apiRequests/aggregatorTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

type WhitelistOrBlacklistContractTaxProps = {
  isWhitelist: boolean;
} & SectionProps;
export const WhitelistOrBlacklistContractTax = ({
  isWhitelist,
  contractAddress,
  handleContractCall
}: WhitelistOrBlacklistContractTaxProps) => {
  const [address, setAddress] = useState('');
  return (
    <div className='row'>
      <div className='col-12'>
        <h2>{isWhitelist ? 'Whitelist' : 'Blacklist'} Contract Tax </h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='Address'
              placeholder='Address'
              callback={(newValue) => setAddress(newValue)}
            />

            <div className='mt-1'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(whitelistOrBlacklistContractTax, [
                    contractAddress,
                    address,
                    isWhitelist
                      ? 'whitelistContractTax'
                      : 'blacklistContractTax'
                  ])
                }
              >
                {isWhitelist ? 'Whitelist' : 'Blacklist'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
