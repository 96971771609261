import React from 'react';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';

export interface AmountWithLabelProps {
  label: string;
  amount?: string;
  decimals?: number;
  tokenLabel?: string;
}

const AmountWithLabel = ({
  label,
  amount,
  decimals,
  tokenLabel
}: AmountWithLabelProps) => {
  const amountLabel = tokenLabel && tokenLabel.length > 0 ? tokenLabel : ' ';

  return (
    <p>
      <b>{label}</b>{' '}
      <FormatAmount
        value={amount ?? '0'}
        decimals={decimals ?? 0}
        egldLabel={amountLabel}
        digits={4}
        showLastNonZeroDecimal={false}
      />
    </p>
  );
};

export default AmountWithLabel;
