import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { RESUME_ALL_FARMS } from 'apiRequests/mutations/resumeAllFarms';
import useGetAndSendTransactions from './useGetAndSendTransactions';

const useResumeAllFarms = () => {
  const { address } = useGetAccount();

  const displayInfo = {
    processingMessage: 'Resume all farms',
    errorMessage: 'An error has occurred while resuming all farms',
    successMessage: 'Farms resumed'
  };

  const [mutateResumeAllFarms] = useGetAndSendTransactions(
    RESUME_ALL_FARMS,
    displayInfo
  );

  const resumeAllFarms = () => {
    mutateResumeAllFarms({
      variables: {
        sender: address
      }
    });
  };

  return resumeAllFarms;
};

export default useResumeAllFarms;
