import React from 'react';
import { useGetFavoriteTokens, useLocalStorage } from 'hooks';
import useGetContractCall from 'hooks/useGetContractCall';
import { AddFavoriteToken } from './AddFavoriteToken';
import { AddOrRemoveTrustedSource } from './AddOrRemoveTrustedSource';
import { AddPair } from './AddPair';
import { AggregatorView } from './AggregatorView';
import { CollectAccumulatedTax } from './CollectAccumulatedTax';
import { RemoveFavoriteToken } from './RemoveFavoriteToken';
import { RemovePair } from './RemovePair';
import { SetFaucetScAddress } from './SetFaucetScAddress';
import { SetTaxPerAction } from './SetTaxPerAction';
import { WhitelistOrBlacklistContractTax } from './WhitelistOrBlacklistContractTax';
import { WhitelistOrRemoveRouter } from './WhitelistRouter';
export const AggregatorPage = () => {
  const [aggregatorAddress, setAggregatorAddress] = useLocalStorage(
    'aggregatorContract',
    ''
  );

  const { handleContractCall, refreshData } =
    useGetContractCall(aggregatorAddress);

  const { data: favoriteTokens, refetch } = useGetFavoriteTokens();

  return (
    <div className='container mt-5'>
      <h2 className='mb-5'>Aggregator settings</h2>
      <div className='card my-3'>
        <div className='card-body'>
          <p>
            <b>Aggregator contract address</b>
          </p>
          <input
            className='form-control'
            type='text'
            placeholder='Faucet contract address'
            value={aggregatorAddress}
            onChange={(event) => setAggregatorAddress(event.target.value)}
          />
        </div>
      </div>
      <AggregatorView
        favoriteTokens={favoriteTokens}
        refreshData={refreshData}
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <AddPair
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <RemovePair
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <AddOrRemoveTrustedSource
        isAdd={true}
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />

      <AddOrRemoveTrustedSource
        isAdd={false}
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <WhitelistOrRemoveRouter
        isWhitelist={true}
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <WhitelistOrRemoveRouter
        isWhitelist={false}
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />

      <SetFaucetScAddress
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <SetTaxPerAction
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />

      <CollectAccumulatedTax
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />

      <WhitelistOrBlacklistContractTax
        isWhitelist={true}
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <WhitelistOrBlacklistContractTax
        isWhitelist={false}
        contractAddress={aggregatorAddress}
        handleContractCall={handleContractCall}
      />
      <AddFavoriteToken favoriteTokens={favoriteTokens} refetch={refetch} />
      <RemoveFavoriteToken refetch={refetch} />
    </div>
  );
};
