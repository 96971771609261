import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { PAUSE_FARM } from 'apiRequests/mutations/pauseFarm';
import useGetAndSendTransactions from './useGetAndSendTransactions';

const usePauseFarm = () => {
  const { address } = useGetAccount();

  const displayInfo = {
    processingMessage: 'Pause farm',
    errorMessage: 'An error has occurred while pausing farm',
    successMessage: 'Farm paused'
  };

  const [mutatePauseFarm] = useGetAndSendTransactions(PAUSE_FARM, displayInfo);

  const pauseFarm = (farmAddress: string) => {
    mutatePauseFarm({
      variables: {
        sender: address,
        farmAddress
      }
    });
  };

  return pauseFarm;
};

export default usePauseFarm;
