import React, { useState } from 'react';
import { addOrRemoveTrustedSource } from 'apiRequests/aggregatorTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';
type AddOrRemoveTrustedSourceProps = {
  isAdd: boolean;
} & SectionProps;
export const AddOrRemoveTrustedSource = ({
  isAdd,
  contractAddress,
  handleContractCall
}: AddOrRemoveTrustedSourceProps) => {
  const [source, setSource] = useState('');
  return (
    <div className='row'>
      <div className='col-12'>
        <h2>{isAdd ? 'Add' : 'Remove'} Trusted Source</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='Source'
              placeholder='Source'
              callback={(newValue) => setSource(newValue)}
            />

            <div className='mt-1'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(addOrRemoveTrustedSource, [
                    contractAddress,
                    source,
                    isAdd ? 'addTrustedSource' : 'removeTrustedSource'
                  ])
                }
              >
                {isAdd ? 'Add' : 'Remove'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
