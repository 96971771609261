import React from 'react';
import { useLocalStorage } from 'hooks';
import useGetContractCall from 'hooks/useGetContractCall';
import CollectAccumulatedTax from './CollectAccumulatedTax/CollectAccumulatedTax';
import AggregatorContractAddress from './SetAggregatorContractAddress/AggregatorContractAddress';
import SetDivisionSafetyConstant from './SetDivisionSafetyConstant/SetDivisionSafetyConstant';
import SetFeeContractDeployment from './SetFeeContractDeployment/SetFeeContractDeployment';
import SetFeeGroupRegistration from './SetFeeGroupRegistration/SetFeeGroupRegistration';
import NFTSourceContractAddress from './SetNFTSourceContractAddress/NFTSourceContractAddress';
import SourceContractAddress from './SetSourceContractAddress/SourceContractAddress';
import SetSpecialAccessAddress from './SetSpecialAccessAddress/SetSpecialAccessAddress';
import SetTaxPerAction from './SetTaxPerAction/SetTaxPerAction';
import SetVestingContractAddress from './SetVestingContractAddress/SetVestingContractAddress';

const StakingRouterPage = () => {
  const [routerAddress, setRouterAddress] = useLocalStorage<string>(
    'routerAddress',
    ''
  );

  const { handleContractCall } = useGetContractCall(routerAddress);

  return (
    <div className='container mt-5'>
      <h2 className='mb-5'>Staking router</h2>
      <div className='card my-3'>
        <div className='card-body'>
          <p>
            <b>Router address</b>
          </p>
          <input
            className='form-control'
            type='text'
            placeholder='Pair router contract address'
            value={routerAddress}
            onChange={(event) => setRouterAddress(event.target.value)}
          />
        </div>
      </div>
      <SourceContractAddress
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <NFTSourceContractAddress
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <SetVestingContractAddress
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <AggregatorContractAddress
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <SetDivisionSafetyConstant
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <SetFeeGroupRegistration
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <SetFeeContractDeployment
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <SetSpecialAccessAddress
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <SetTaxPerAction
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
      <CollectAccumulatedTax
        contractAddress={routerAddress}
        handleContractCall={handleContractCall}
      />
    </div>
  );
};

export default StakingRouterPage;
