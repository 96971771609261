import { gql } from '@apollo/client';

export const PAUSE_FARM = gql`
  mutation pauseFarm($sender: String!, $farmAddress: String!) {
    transactions: pauseFarm(sender: $sender, farmAddress: $farmAddress) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
