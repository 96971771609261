import { useEffect, useState } from 'react';
import { getAggregatorSettings } from 'apiRequests/aggregator';
import { AggregatorSettings } from 'types/AggregatorSettings';

const useGetAggregatorSettings = (contractAddress: string | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<AggregatorSettings>();

  const init = async () => {
    if (!contractAddress || contractAddress === '') {
      return;
    }

    try {
      setIsLoading(true);

      const _stats = await getAggregatorSettings(contractAddress);
      setSettings(_stats);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [contractAddress]);

  return { isLoading, settings, init };
};

export default useGetAggregatorSettings;
