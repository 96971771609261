import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { RESUME_FARM } from 'apiRequests/mutations/resumeFarm';
import useGetAndSendTransactions from './useGetAndSendTransactions';

const useResumeFarm = () => {
  const { address } = useGetAccount();

  const displayInfo = {
    processingMessage: 'Resume farm',
    errorMessage: 'An error has occurred while resuming farm',
    successMessage: 'Farm resumed'
  };

  const [mutateResumeFarm] = useGetAndSendTransactions(
    RESUME_FARM,
    displayInfo
  );

  const resumeFarm = (farmAddress: string) => {
    mutateResumeFarm({
      variables: {
        sender: address,
        farmAddress
      }
    });
  };

  return resumeFarm;
};

export default useResumeFarm;
