import React, { useState } from 'react';
import { setSpecialFeeSwapPair } from 'apiRequests';
import TextField from 'components/TextField';
import { SectionProps } from '../SectionProps';

const SpecialFeeSwapPair = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [address, setAddress] = useState('');
  const [firstToken, setFirstToken] = useState('');
  const [secondToken, setSecondToken] = useState('');

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set special fee swap pair</b>
        </p>

        <TextField
          type='text'
          label='Address'
          placeholder='Address'
          callback={(newValue) => setAddress(newValue)}
        />

        <TextField
          type='text'
          label='First token'
          placeholder='First token'
          callback={(newValue) => setFirstToken(newValue)}
        />

        <TextField
          type='text'
          label='Second token'
          placeholder='Second token'
          callback={(newValue) => setSecondToken(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setSpecialFeeSwapPair, [
                contractAddress,
                address?.trim(),
                firstToken?.trim(),
                secondToken?.trim()
              ])
            }
          >
            Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpecialFeeSwapPair;
