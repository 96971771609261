import {
  AddressValue,
  Address,
  BooleanValue,
  TokenIdentifierValue,
  U64Value
} from '@multiversx/sdk-core/out';
import { performTransaction, TransactionResponse } from './generalRequests';

export const pauseOrResumeContractState = async (
  contractAddress: string,
  method: 'pause' | 'resume' | 'unpause'
): Promise<TransactionResponse> => {
  return await performTransaction(method, contractAddress, []);
};

export const setStateActiveNoSwaps = async (
  contractAddress: string
): Promise<TransactionResponse> => {
  return await performTransaction('setStateActiveNoSwaps', contractAddress, []);
};

export const whitelistOrRemoveWhitelistForAddress = async (
  contractAddress: string,
  address: string,
  method: 'whitelist' | 'removeWhitelist'
): Promise<TransactionResponse> => {
  return await performTransaction(method, contractAddress, [
    new AddressValue(new Address(address))
  ]);
};

export const setFeeOn = async (
  contractAddress: string,
  feeToAddress: string,
  feeToken: string,
  enabled: boolean
): Promise<TransactionResponse> => {
  return await performTransaction('setFeeOn', contractAddress, [
    new BooleanValue(enabled),
    new AddressValue(new Address(feeToAddress)),
    new TokenIdentifierValue(feeToken)
  ]);
};

export const setFeePercents = async (
  contractAddress: string,
  totalFeePercent: string,
  specialFeePercent: string
): Promise<TransactionResponse> => {
  return await performTransaction('setFeePercents', contractAddress, [
    new U64Value(totalFeePercent),
    new U64Value(specialFeePercent)
  ]);
};

export const addTrustedSwapPair = async (
  contractAddress: string,
  pairAddress: string,
  firstTokenId: string,
  secondTokenId: string
): Promise<TransactionResponse> => {
  return await performTransaction('addTrustedSwapPair', contractAddress, [
    new AddressValue(new Address(pairAddress)),
    new TokenIdentifierValue(firstTokenId),
    new TokenIdentifierValue(secondTokenId)
  ]);
};

export const removeTrustedSwapPair = async (
  contractAddress: string,
  firstTokenId: string,
  secondTokenId: string
): Promise<TransactionResponse> => {
  return await performTransaction('removeTrustedSwapPair', contractAddress, [
    new TokenIdentifierValue(firstTokenId),
    new TokenIdentifierValue(secondTokenId)
  ]);
};
