import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { QUERY_FAVORITE_TOKENS } from 'apiRequests/queries/getFavoriteTokens';
import { FavoriteToken } from 'types';

export const POLLING_INTERVAL = 6000;

const useGetFavoriteTokens = (): {
  data: FavoriteToken[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: any;
} => {
  const { data, loading, error, refetch } = useQuery(QUERY_FAVORITE_TOKENS);

  return React.useMemo(() => {
    return {
      data: data?.favoriteTokens ?? [],
      loading,
      error,
      refetch
    };
  }, [data, loading, error]);
};

export { useGetFavoriteTokens };
