import React, { useMemo } from 'react';
import ValueWithLabel from 'components/ValueWithLabel/ValueWithLabel';
import useGetAggregatorSettings from 'hooks/useGetAggregatorSettings';
import { SectionProps } from 'pages/PairRouter';
import { FavoriteToken } from 'types';

type AggregatorViewProps = {
  favoriteTokens: FavoriteToken[];
} & SectionProps;

export const AggregatorView = ({
  contractAddress,
  refreshData,
  favoriteTokens
}: AggregatorViewProps) => {
  const { isLoading, settings, init } =
    useGetAggregatorSettings(contractAddress);

  React.useEffect(() => {
    if (!refreshData || isLoading) {
      return;
    }
    init();
  }, [refreshData]);

  const sortedTokens = useMemo(() => {
    return [...favoriteTokens].sort((a, b) => a.position - b.position);
  }, [favoriteTokens]);

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Aggregator contract state</b>
        </p>

        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <ValueWithLabel
              label={'Contract address:'}
              value={settings?.contractAddress}
            />
            <ValueWithLabel
              label={'Tax white list:'}
              value={settings?.taxWhitelist.toString()}
            />
            <ValueWithLabel
              label={'Tax per action token:'}
              value={settings?.taxPerActionToken}
            />
            <ValueWithLabel
              label={'Tax per action value:'}
              value={settings?.taxPerActionValue?.toString()}
            />
            <ValueWithLabel
              label={'Collected Tax Amount:'}
              value={settings?.collectedTaxAmount?.toString()}
            />
            <div className='d-flex flex-row'>
              <b>Favorite tokens:</b>
              {sortedTokens.length === 0 ? (
                ' -'
              ) : (
                <>
                  <div className='d-flex flex-column'>
                    {sortedTokens.map((token, index) => (
                      <span key={index}>{token.tokenId}</span>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
