import React, { useState } from 'react';
import { setPairTemplateAddress } from 'apiRequests';
import TextField from 'components/TextField';
import { SectionProps } from '../SectionProps';

const PairTemplateAddress = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [address, setAddress] = useState<string>();

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set pair template address</b>
        </p>

        <TextField
          type='text'
          label='Address'
          placeholder='Address'
          callback={(newValue) => setAddress(newValue)}
        />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setPairTemplateAddress, [
                contractAddress,
                address
              ])
            }
          >
            Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default PairTemplateAddress;
