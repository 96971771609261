import React, { useState } from 'react';

import {
  TokenIdentifierValue,
  OptionalValue,
  AddressType,
  AddressValue,
  Address
} from '@multiversx/sdk-core/out';
import TextField from 'components/TextField';
import useWasmCode from 'hooks/useWasmCode';
import { DeploySectionProps } from '../DeploySectionProps';

const DeployRouterContract = ({ handleTransaction }: DeploySectionProps) => {
  const [contractAddress, setContractAddress] = useState('');
  const [wrappedEgld, setWrappedEgld] = useState('');
  const [pairTemplateAddress, setPairTemplateAddress] = useState('');
  const { code, onChange } = useWasmCode();

  const handleSubmission = async (method: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    const scAddress = method == 'deploy' ? undefined : contractAddress;

    handleTransaction(method, scAddress, code, getTransactionArgs(), 200000000);
  };

  const getTransactionArgs = () => {
    const args = [new TokenIdentifierValue(wrappedEgld?.trim())];

    if (pairTemplateAddress) {
      return [
        ...args,
        new OptionalValue(
          new AddressType(),
          new AddressValue(new Address(pairTemplateAddress?.trim()))
        )
      ];
    }

    return args;
  };

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Deploy/Upgrade router contract</b>
        </p>

        <TextField
          type='text'
          label='Pair router contract address (only for upgrade)'
          placeholder='Pair router contract address'
          callback={(newValue) => setContractAddress(newValue)}
        />

        <TextField
          type='text'
          label='Wrapped EGLD id'
          placeholder='Wrapped EGLD id'
          callback={(newValue) => setWrappedEgld(newValue)}
        />

        <TextField
          type='text'
          label='Pair template address'
          placeholder='Pair template address'
          callback={(newValue) => setPairTemplateAddress(newValue)}
        />

        <input type='file' name='file' onChange={onChange} />

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() => handleSubmission('deploy')}
          >
            Deploy
          </button>
          <button
            className='btn btn-primary mr-4'
            onClick={() => handleSubmission('upgrade')}
          >
            Upgrade
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeployRouterContract;
