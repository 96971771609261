import React from 'react';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { GET_FARMS_V1, GET_FARMS_V2 } from 'apiRequests/queries/getAllFarms';
import { GroupFarm } from 'types/farm';
import { FarmStakingVersion } from 'types/FarmVersion';

const useGetFarms = (
  version: FarmStakingVersion
): {
  data: GroupFarm[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: any) => Promise<ApolloQueryResult<any>>;
} => {
  const { data, loading, error, refetch } = useQuery(
    version === FarmStakingVersion.V1 ? GET_FARMS_V1 : GET_FARMS_V2
  );

  return React.useMemo(() => {
    const returnedData = data?.farms;
    return {
      data: returnedData
        ? returnedData
            .map((group: any) => GroupFarm.fromResponse(group))
            .filter((group: GroupFarm) => group.farms.length > 0)
        : undefined,
      loading,
      error,
      refetch
    };
  }, [data, loading, error]);
};

export { useGetFarms };
