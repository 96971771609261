import React from 'react';

export interface ValueWithLabelProps {
  label: string;
  value?: string;
}

const ValueWithLabel = ({ label, value }: ValueWithLabelProps) => {
  return (
    <p>
      <b>{label}</b> {value ?? '-'}
    </p>
  );
};

export default ValueWithLabel;
