import React from 'react';
import { GroupFarm } from 'types/farm';
import { FarmStakingVersion } from 'types/FarmVersion';
import FarmCard from './FarmCard';

interface GroupFarmCardProps {
  groupFarm: GroupFarm;
  version: FarmStakingVersion;
}

const GroupFarmCard = ({ groupFarm, version }: GroupFarmCardProps) => {
  return (
    <div className='container mt-5'>
      <h3 className='mb-2'>{groupFarm.groupId}</h3>
      {groupFarm.farms.map((farm) => (
        <FarmCard
          farm={farm}
          key={farm.scAddress}
          groupId={groupFarm.groupId}
          version={version}
        />
      ))}
    </div>
  );
};
export default GroupFarmCard;
