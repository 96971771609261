import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import TextField from 'components/TextField';
import { useRemoveTokenFromFavorites } from 'hooks/useRemoveTokenFromFavorites';
import { isValidToken } from 'utils/isValidToken';

interface RemoveFavoriteTokenProps {
  refetch: () => void;
}

export const RemoveFavoriteToken = ({ refetch }: RemoveFavoriteTokenProps) => {
  const [tokenId, setTokenId] = useState('');

  const { performMutation, data, loading } = useRemoveTokenFromFavorites();

  const removeToken = () => {
    if (!isValidToken(tokenId)) {
      return;
    }

    performMutation(tokenId);
  };

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Remove token from favorites</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='Token id'
              placeholder='Token id'
              callback={(newValue) => setTokenId(newValue)}
            />

            <div className='mt-1'>
              <button className='btn btn-primary mr-4' onClick={removeToken}>
                {loading ? (
                  <Spinner as='span' animation='border' size='sm' />
                ) : (
                  'Remove token from favorites'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
