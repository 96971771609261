import React from 'react';
import useGetContractCall from 'hooks/useGetContractCall';
import { useLocalStorage } from 'hooks/useLocalStorage';
import PairCreationEnabled from './PairCreationEnabled/PairCreationEnabled';
import PairRouterViews from './PairRouterViews/PairRouterViews';
import PairTemplateAddress from './PairTemplateAddress/PairTemplateAddress';
import AggregatorContractAddress from './SetAggregatorContractAddress/AggregatorContractAddress';
import SetFeeDetails from './SetFeeDetails/SetFeeDetails';
import SpecialFeeSwapPair from './SpecialFeeSwapPair/SpecialFeeSwapPair';

const PairRouterPage = () => {
  const [pairRouterAddress, setPairRouterAddress] = useLocalStorage(
    'pairRouterContract',
    ''
  );

  const { handleContractCall, refreshData } =
    useGetContractCall(pairRouterAddress);

  return (
    <div className='container mt-5'>
      <h2 className='mb-5'>Pair router settings</h2>
      <div className='card my-3'>
        <div className='card-body'>
          <p>
            <b>Pair router contract address</b>
          </p>
          <input
            className='form-control'
            type='text'
            placeholder='Pair router contract address'
            value={pairRouterAddress}
            onChange={(event) => setPairRouterAddress(event.target.value)}
          />
        </div>
      </div>
      <PairRouterViews
        refreshData={refreshData}
        contractAddress={pairRouterAddress}
        handleContractCall={handleContractCall}
      />
      <SetFeeDetails
        contractAddress={pairRouterAddress}
        handleContractCall={handleContractCall}
      />
      <SpecialFeeSwapPair
        contractAddress={pairRouterAddress}
        handleContractCall={handleContractCall}
      />
      <PairCreationEnabled
        contractAddress={pairRouterAddress}
        handleContractCall={handleContractCall}
      />
      <PairTemplateAddress
        contractAddress={pairRouterAddress}
        handleContractCall={handleContractCall}
      />
      <AggregatorContractAddress
        contractAddress={pairRouterAddress}
        handleContractCall={handleContractCall}
      />
    </div>
  );
};
export default PairRouterPage;
