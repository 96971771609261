import { gql } from '@apollo/client';

export const PAUSE_ALL_FARMS = gql`
  mutation pauseAllFarms($sender: String!) {
    transactions: pauseAllFarms(sender: $sender) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
