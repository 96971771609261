import React from 'react';
import { setPairCreationEnabled } from 'apiRequests';
import { SectionProps } from '../SectionProps';

const PairCreationEnabled = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set pair creation enabled/disabled</b>
        </p>

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setPairCreationEnabled, [
                contractAddress,
                true
              ])
            }
          >
            Enable
          </button>
          <button
            className='btn btn-danger mr-4'
            onClick={() =>
              handleContractCall(setPairCreationEnabled, [
                contractAddress,
                false
              ])
            }
          >
            Disable
          </button>
        </div>
      </div>
    </div>
  );
};

export default PairCreationEnabled;
