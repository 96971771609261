import { useState } from 'react';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { TransactionResponse } from 'apiRequests';
import { useLocalStorage } from './useLocalStorage';

const useGetContractCall = (contractAddress: string | undefined) => {
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'sessionId',
    null
  );

  const [refreshData, setRefreshData] = useState<number | undefined>(undefined);

  const handleRefreshData = () => {
    setSessionId(null);
    setRefreshData((prev: number | undefined) => {
      if (prev === undefined) {
        return 1;
      }

      return prev + 1;
    });
  };

  const handleContractCall = async (
    fetcher: (...payload: any) => Promise<TransactionResponse>,
    payload?: any
  ) => {
    if (!contractAddress) {
      return;
    }

    const response = await fetcher(...payload);
    setSessionId(response.sessionId);
  };

  useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleRefreshData,
    onFail: handleRefreshData,
    onCancelled: handleRefreshData
  });

  return { handleContractCall, refreshData };
};

export default useGetContractCall;
