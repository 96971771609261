import React from 'react';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import { Spinner } from 'react-bootstrap';
import usePauseFarm from 'hooks/usePauseFarm';
import useResumeFarm from 'hooks/useResumeFarm';
import useUpgradeFarm from 'hooks/useUpgradeFarm';
import PairInfo from 'pages/Pairs/PairInfo';
import { FarmDataStruct } from 'types/farm';
import { FarmStakingVersion } from 'types/FarmVersion';

export interface FarmCardProps {
  farm: FarmDataStruct;
  groupId: string;
  version: FarmStakingVersion;
}

const FarmCard = ({ farm, groupId, version }: FarmCardProps) => {
  const upgradeFarm = useUpgradeFarm(version);
  const pauseFarm = usePauseFarm();
  const resumeFarm = useResumeFarm();

  const { pending } = useGetActiveTransactionsStatus();

  return (
    <div className='card my-3'>
      <div className='card-body'>
        <div className='d-flex flex-column'>
          <PairInfo title={'SC address:'} description={farm.scAddress} />
          <PairInfo
            title={'Farming token id:'}
            description={farm.farmingTokenId}
          />
          <PairInfo
            title={'Reward token id:'}
            description={farm.rewardTokenId}
          />
          <PairInfo
            title={'Are rewards locked:'}
            description={farm.areRewardsLocked}
          />
          <PairInfo title={'Farm token id:'} description={farm.farmTokenId} />
          <PairInfo title={'State:'} description={farm.state} />
          <div className='mt-3 d-flex justify-content-between'>
            <button
              style={{ minWidth: '130px' }}
              className='btn btn-primary'
              onClick={() => upgradeFarm(farm.scAddress, groupId)}
            >
              {pending ? (
                <Spinner as='span' animation='border' size='sm' />
              ) : (
                <span>Upgrade farm</span>
              )}
            </button>
            <div>
              <button
                style={{ minWidth: '130px' }}
                className='btn btn-primary mr-2'
                onClick={() => resumeFarm(farm.scAddress)}
              >
                {pending ? (
                  <Spinner as='span' animation='border' size='sm' />
                ) : (
                  <span>Resume</span>
                )}
              </button>
              <button
                style={{ minWidth: '130px' }}
                className='btn btn-primary'
                onClick={() => pauseFarm(farm.scAddress)}
              >
                {pending ? (
                  <Spinner as='span' animation='border' size='sm' />
                ) : (
                  <span>Pause</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmCard;
