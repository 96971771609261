import * as React from 'react';
import {
  AbiRegistry,
  BytesValue,
  Struct,
  Field,
  U64Value
} from '@multiversx/sdk-core/out';
import * as factoryAbi from 'abis/factory.abi.json';
import useWasmCode from 'hooks/useWasmCode';
import { DeploySectionProps } from '../DeploySectionProps';

const DeployVestingContract = ({ handleTransaction }: DeploySectionProps) => {
  const { code, onChange } = useWasmCode();

  const handleSubmission = async (operation: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    const factoryJson = factoryAbi;
    const registry = AbiRegistry.create(factoryJson.abi as any);
    const unlockMilestoneType = registry.getStruct('UnlockMilestone');

    const args = [
      new BytesValue(Buffer.from('LAUNCH-abcdef')),
      new Struct(unlockMilestoneType, [
        new Field(new U64Value(1), 'unlock_epoch'),
        new Field(new U64Value(100000), 'unlock_percent')
      ])
    ];

    handleTransaction(operation, undefined, code, args, 150000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Vesting contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <input type='file' name='file' onChange={onChange} />

            <div className='mt-2'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission('deploy')}
              >
                Deploy
              </button>
            </div>
            <div>
              <pre className='pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployVestingContract;
