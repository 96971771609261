import * as React from 'react';
import { useState } from 'react';
import { setNFTSourceContractAddress } from 'apiRequests/stakingRouterTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

const NFTSourceContractAddress = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  const [address, setAddress] = useState('');

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Set NFT source contract address</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='NFT Source contract address'
              placeholder='NFT Source contract address'
              callback={(newValue) => setAddress(newValue)}
            />

            <div className='mt-1'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(setNFTSourceContractAddress, [
                    contractAddress,
                    address
                  ])
                }
              >
                Set
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTSourceContractAddress;
