import * as React from 'react';
import { useState } from 'react';
import TextField from 'components/TextField';
import useWasmCode from 'hooks/useWasmCode';
import { DeploySectionProps } from '../DeploySectionProps';

const DeployVampiresContract = ({ handleTransaction }: DeploySectionProps) => {
  const { code, onChange } = useWasmCode();
  const [address, setAddress] = useState('');

  const handleSubmission = async (method: 'deploy' | 'upgrade') => {
    if (!code) {
      return;
    }

    const scAddress = method == 'deploy' ? undefined : address;

    handleTransaction(method, scAddress, code, [], 100000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Vampires contract</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <p>Deploy arguments:</p>
            <TextField
              type='text'
              label='Vampires/Villagers contract address (only for upgrade)'
              placeholder='Vampires/Villagers contract address'
              callback={(newValue) => setAddress(newValue)}
            />
            <input
              className='mt-2'
              type='file'
              name='file'
              onChange={onChange}
            />

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission('deploy')}
              >
                Deploy
              </button>
              <button
                className='btn btn-primary'
                onClick={() => handleSubmission('upgrade')}
              >
                Upgrade
              </button>
            </div>
            <div>
              <pre className='mt-2 pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployVampiresContract;
