import { useEffect, useState } from 'react';
import { getAllPairsMetadata } from 'apiRequests';
import { PairContractMetadata } from 'types';

const useGetPairsMetadata = (contractAddress: string | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pairs, setPairs] = useState<PairContractMetadata[]>();

  const init = async () => {
    if (!contractAddress || contractAddress === '') {
      return;
    }

    try {
      setIsLoading(true);

      const _pairs = await getAllPairsMetadata(contractAddress);
      setPairs(_pairs);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [contractAddress]);

  return { isLoading, pairs, refetch: init };
};

export default useGetPairsMetadata;
