import * as React from 'react';

import {
  BytesValue,
  BigUIntValue,
  U64Value,
  BooleanValue
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import useWasmCode from 'hooks/useWasmCode';
import { FarmStakingVersion } from 'types/FarmVersion';
import { DeploySectionProps } from '../DeploySectionProps';

interface DeployFarmStakingContractProps extends DeploySectionProps {
  version: FarmStakingVersion;
}

const DeployFarmStakingContract = ({
  handleTransaction,
  version
}: DeployFarmStakingContractProps) => {
  const { code, onChange } = useWasmCode();

  const handleSubmission = async () => {
    if (!code) {
      return;
    }

    const farmTokenId = 'LAUNCH-abcdef';
    const rewardTokenId = 'LAUNCH-abcdef';
    const divisionSafetyConstant = 1;
    const maxApr = 300;
    const minUnbondEpochs = 3;
    const areRewardsLocked = false;
    const address = '';

    const args = [
      new BytesValue(Buffer.from(farmTokenId)),
      new BytesValue(Buffer.from(rewardTokenId)),
      new BigUIntValue(new BigNumber(divisionSafetyConstant)),
      new BigUIntValue(new BigNumber(maxApr)),
      new U64Value(new BigNumber(minUnbondEpochs)),
      new BooleanValue(areRewardsLocked)
    ];

    if (version === FarmStakingVersion.V2) {
      args.push(
        ...[
          new U64Value(new BigNumber(18)),
          new U64Value(new BigNumber(18)),
          new BooleanValue(true)
        ]
      );
    }

    handleTransaction('deploy', address, code, args, 150000000);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Deploy Farm Staking contract {version}</h2>

        <div className='card mb-2'>
          <div className='card-body'>
            <p>Deploy arguments:</p>
            <input type='file' name='file' onChange={onChange} />

            <div className='mt-3'>
              <button
                className='btn btn-primary mr-4'
                onClick={() => handleSubmission()}
              >
                Deploy
              </button>
            </div>
            <div>
              <pre className='mt-2 pre-scrollable'>
                <code>{code?.toString()}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployFarmStakingContract;
