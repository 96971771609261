import React, { useEffect, useState } from 'react';
import { SearchBar } from 'components/SearchBar';
import useGetContractCall from 'hooks/useGetContractCall';
import useGetPairsMetadata from 'hooks/useGetPairsMetadata';
import Pair from '../Pair';

export interface PairsListProps {
  routerAddress: string;
}

const PairsList = ({ routerAddress }: PairsListProps) => {
  const [filter, setFilter] = useState('');

  const { isLoading, pairs, refetch } = useGetPairsMetadata(routerAddress);

  const filteredPairs = pairs?.filter((pair) => {
    const lowerCaseFilter = filter.toLocaleLowerCase();
    return (
      pair.address.toLocaleLowerCase().includes(lowerCaseFilter) ||
      pair.secondTokenId.toLocaleLowerCase().includes(lowerCaseFilter)
    );
  });

  const { handleContractCall, refreshData } = useGetContractCall(routerAddress);

  useEffect(() => {
    if (!refreshData || isLoading) {
      return;
    }

    refetch();
  }, [refreshData]);

  const hasPairs = () => {
    return filteredPairs !== undefined && filteredPairs.length > 0;
  };

  return (
    <div className='mt-5'>
      <div className='mb-3'>
        <SearchBar
          hint={'Search for pairs (second token id or address)'}
          onChange={(state) => {
            setFilter(state);
          }}
        />
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : !hasPairs() ? (
        <span>No pairs found</span>
      ) : (
        filteredPairs?.map((pair) => (
          <Pair
            key={pair.address}
            pairContractMetadata={pair}
            routerAddress={routerAddress}
            handleContractCall={handleContractCall}
          />
        ))
      )}
    </div>
  );
};

export default PairsList;
