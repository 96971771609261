import React, { useState } from 'react';
import { whitelistOrRemoveWhitelistRouterForAddress } from 'apiRequests/aggregatorTransactions';
import TextField from 'components/TextField';
import { SectionProps } from 'pages/PairRouter';

type WhitelistOrRemoveRouterProps = {
  isWhitelist: boolean;
} & SectionProps;
export const WhitelistOrRemoveRouter = ({
  isWhitelist,
  contractAddress,
  handleContractCall
}: WhitelistOrRemoveRouterProps) => {
  const [address, setAddress] = useState('');
  return (
    <div className='row'>
      <div className='col-12'>
        <h2>{isWhitelist ? 'Whitelist' : 'Remove'} Router </h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='Address'
              placeholder='Address'
              callback={(newValue) => setAddress(newValue)}
            />

            <div className='mt-1'>
              <button
                className='btn btn-primary mr-4'
                onClick={() =>
                  handleContractCall(
                    whitelistOrRemoveWhitelistRouterForAddress,
                    [
                      contractAddress,
                      address,
                      isWhitelist ? 'whitelistRouter' : 'removeRouter'
                    ]
                  )
                }
              >
                {isWhitelist ? 'Whitelist' : 'Remove'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
