import React, { useEffect } from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import { Loader, PageState } from '@multiversx/sdk-dapp/UI';
import { Spinner } from 'react-bootstrap';
import { useLocalStorage } from 'hooks';
import { useGetFarms } from 'hooks/useGetFarms';
import usePauseAllFarms from 'hooks/usePauseAllFarms';
import useResumeAllFarms from 'hooks/useResumeAllFarms';
import useUpgradeAllFarms from 'hooks/useUpgradeAllFarms';
import { FarmStakingVersion } from 'types/FarmVersion';
import GroupFarmCard from './GroupFarmCard';

const FarmsPage = () => {
  const [currentVersion, setCurrentVersion] = useLocalStorage(
    'currentVersion',
    FarmStakingVersion.V1
  );

  const { data, loading, error, refetch } = useGetFarms(currentVersion);

  const upgradeAllFarms = useUpgradeAllFarms(currentVersion);
  const pauseAllFarms = usePauseAllFarms();
  const resumeAllFarms = useResumeAllFarms();

  const { pending, success } = useGetActiveTransactionsStatus();

  const changeVersion = (event: any) => {
    setCurrentVersion(event.target.value);
  };

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  if (loading) {
    return (
      <div className='screen-centered'>
        <Loader noText={true} />
      </div>
    );
  }

  if (error) {
    return (
      <PageState
        icon={faX}
        className='text-muted'
        title='An error has occurred. Please try again later!'
      />
    );
  }

  return (
    <div className='container mt-5'>
      <div className='d-flex flex-row px-2 mb-5'>
        <h2 className='mr-2 flex-grow-1'>Upgrade farms</h2>
        <select value={currentVersion} onChange={changeVersion}>
          <option value={FarmStakingVersion.V1}>Version 1</option>
          <option value={FarmStakingVersion.V2}>Version 2</option>
        </select>
      </div>
      {data && data?.length > 0 && (
        <div className='d-flex flex-row justify-content-around'>
          <button
            style={{ minWidth: '150px' }}
            className='btn btn-primary'
            onClick={() => upgradeAllFarms()}
          >
            {pending ? (
              <Spinner as='span' animation='border' size='sm' />
            ) : (
              <span>Upgrade all farms</span>
            )}
          </button>
          <button
            style={{ minWidth: '150px' }}
            className='btn btn-primary'
            onClick={() => pauseAllFarms()}
          >
            {pending ? (
              <Spinner as='span' animation='border' size='sm' />
            ) : (
              <span>Pause All</span>
            )}
          </button>
          <button
            style={{ minWidth: '150px' }}
            className='btn btn-primary'
            onClick={() => resumeAllFarms()}
          >
            {pending ? (
              <Spinner as='span' animation='border' size='sm' />
            ) : (
              <span>Resume All</span>
            )}
          </button>
        </div>
      )}
      {data?.map((group) => (
        <GroupFarmCard
          key={group.groupId}
          groupFarm={group}
          version={currentVersion}
        />
      ))}
    </div>
  );
};
export default FarmsPage;
