import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import TextField from 'components/TextField';
import { useAddTokenToFavorites } from 'hooks/useAddTokenToFavorites';
import { FavoriteToken } from 'types';
import { isValidToken } from 'utils/isValidToken';

interface AddFavoriteTokenProps {
  favoriteTokens: FavoriteToken[];
  refetch: () => void;
}

export const AddFavoriteToken = ({
  favoriteTokens,
  refetch
}: AddFavoriteTokenProps) => {
  const [tokenId, setTokenId] = useState('');
  const [position, setPosition] = useState('');

  const { performMutation, data, loading } = useAddTokenToFavorites();

  const tokenIds = useMemo(() => {
    return favoriteTokens.map((token) => token.tokenId);
  }, [favoriteTokens]);

  const addToken = () => {
    if (
      !isValidToken(tokenId) ||
      tokenIds.includes(tokenId) ||
      position.length === 0
    ) {
      return;
    }

    const parsedPosition = parseInt(position);

    if (isNaN(parsedPosition) || parsedPosition < 0 || parsedPosition > 5) {
      return;
    }

    performMutation(tokenId, parseInt(position));
  };

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  return (
    <div className='row'>
      <div className='col-12'>
        <h2>Add token to favorites</h2>

        <div className='card mb-2'>
          <div className='d-flex flex-column card-body'>
            <TextField
              type='text'
              label='Token id'
              placeholder='Token id'
              callback={(newValue) => setTokenId(newValue)}
            />
            <TextField
              type='number'
              label='Position'
              placeholder='Position'
              callback={(newValue) => setPosition(newValue)}
            />

            <div className='mt-1'>
              <button className='btn btn-primary mr-4' onClick={addToken}>
                {loading ? (
                  <Spinner as='span' animation='border' size='sm' />
                ) : (
                  'Add token to favorites'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
