import React from 'react';

export interface ManagedAddressesListProps {
  addresses?: string[];
}

const ManagedAddressesList = ({ addresses }: ManagedAddressesListProps) => {
  const hasAddresses = () => {
    return addresses !== undefined && addresses.length > 0;
  };

  return (
    <div className='mb-3'>
      <p>
        <b>Whitlisted managed addresses:</b>
      </p>
      <div className='ml-4'>
        {hasAddresses() ? (
          <div>There are no whitelisted managed addresses</div>
        ) : (
          addresses?.map((address) => <p key={address}>{address}</p>)
        )}
      </div>
    </div>
  );
};

export default ManagedAddressesList;
