import React from 'react';
import { setStateActiveNoSwaps } from 'apiRequests/pairTransactions';
import { SectionProps } from 'pages/PairRouter/SectionProps';

const StateActiveNoSwaps = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Set state active no swaps</b>
        </p>

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(setStateActiveNoSwaps, [contractAddress])
            }
          >
            Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default StateActiveNoSwaps;
