import React from 'react';
import { pauseOrResumeContractState } from 'apiRequests/pairTransactions';
import { SectionProps } from 'pages/PairRouter/SectionProps';

const PauseFaucetContract = ({
  contractAddress,
  handleContractCall
}: SectionProps) => {
  return (
    <div className='card my-3'>
      <div className='card-body'>
        <p>
          <b>Pause/Resume faucet contract</b>
        </p>

        <div className='mt-3'>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(pauseOrResumeContractState, [
                contractAddress,
                'pause'
              ])
            }
          >
            Pause
          </button>
          <button
            className='btn btn-primary mr-4'
            onClick={() =>
              handleContractCall(pauseOrResumeContractState, [
                contractAddress,
                'unpause'
              ])
            }
          >
            Resume
          </button>
        </div>
      </div>
    </div>
  );
};

export default PauseFaucetContract;
