import { gql } from '@apollo/client';

export const QUERY_FAVORITE_TOKENS = gql`
  query favoriteTokens {
    favoriteTokens {
      tokenId
      position
    }
  }
`;
